import { combineReducers } from "redux";
import loginController from "./loginController";
import contentSlice from "./contentSlice";
import { LOGOUT } from "./actionTypes"; // Import a LOGOUT action type
import imageReducer from "./ImageSlice";
import PublishButtonLoadingSlice from "./PublishButtonLoadingSlice";

const appReducer = combineReducers({
  userAuthentication: loginController,
  content: contentSlice,
  image: imageReducer,
  publishButtonLoading: PublishButtonLoadingSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    // Clear all data in redux store on logout
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
