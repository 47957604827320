import React, { useState } from "react";
import resetPassword from "../../assets/svg/resetPassword.svg";
// import Toast from "components/ToastMessges/ToastMessges";
import Loader from "components/icons/Loader";
import { UserPasswordReset } from "./AuthHelper";
import { useNavigate } from "react-router-dom";
import LeftToRightMotion from "components/Motions/LeftToRightMotion";
import NewToast from "components/ToastMessges/NewToast";

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    if (!password || !confirmPassword) {
      setError("Both password fields are required.");
      setToast({
        message: "Both password fields are required.",
        type: "error",
      });
      return;
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setToast({ message: "Passwords do not match.", type: "error" });
      return;
    }

    setLoading(true);

    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get("token");
      let verifytoken = localStorage.getItem("verifytoken");
      let payload = {
        token: tokenFromUrl || verifytoken,
        newPassword: password,
      };
      let res = await UserPasswordReset(payload);
      if (res.success) {
        localStorage.removeItem("verifytoken");
        setToast({
          message: "Password reset successful moving to singup screen.",
          type: "success",
        });
        setTimeout(() => {
          navigate("/auth/sign-in");
        }, 1500);
      } else {
        setToast({
          message: res?.message || "Failed to reset password.",
          type: "error",
        });
      }
    } catch (error) {
      setToast({
        message: "Failed to reset password.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {toast && (
        <NewToast
          message={toast.message}
          type={toast.type}
          vertical={"top"}
          horizontal={"right"}
          onClose={() => setToast(null)}
        />
      )}
      <LeftToRightMotion>
        <div className="relative flex min-h-96 flex-col md:mt-[4rem] md:flex-row">
          {/* Image Section */}
          <div className="flex w-full items-center justify-center p-4 md:w-1/2 md:p-0">
            <img
              src={resetPassword}
              alt="Reset Password"
              className="w-full max-w-md"
            />
          </div>

          {/* Form Section */}
          <div className="flex w-full items-center justify-center p-4 md:w-1/2">
            <form
              onSubmit={handleSubmit}
              className="w-full max-w-sm rounded-lg bg-white p-8"
            >
              <label className="mb-4 block">
                <span className="text-gray-700">New Password</span>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter new password"
                />
              </label>
              <label className="mb-4 block">
                <span className="text-gray-700">Confirm New Password</span>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Confirm new password"
                />
                {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
              </label>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="flex items-center justify-center rounded bg-[#5334FF] px-4 py-2 text-white hover:bg-blue-600"
                  disabled={loading}
                >
                  Save Changes
                  {loading && <Loader />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </LeftToRightMotion>
    </>
  );
};

export default ResetPassword;
