import { getAccessToken } from "views/auth/AuthHelper";
import axios from "axios";

export const generateMockData = () => {
  return {
    message: "Request processed successfully.",
    data: {
      sessionId: "7e529142-9ed9-4642-be40-3a8e12144b1f",
      post: "Node.js developers, let's discuss! How does the Node.js Event Loop handle CPU-intensive tasks compared to other languages like Go? Share your experiences in the comments!",
      options: [
        "Efficiently handles",
        "Struggles with them",
        "Depends on task complexity",
      ],
      duration: "THREE_DAYS",
      question: "How does the Node.js Event Loop handle CPU-intensive tasks?",
    },
    success: true,
  };
};

export const generatePoolContentAPI = async (payload: object) => {
  let token = getAccessToken();

  // Check if in development mode and return mock data
  if (process.env.NODE_ENV === "development") {
    return new Promise((resolve) => {
      setTimeout(() => resolve(generateMockData()), 4000); // Simulate API delay
    });
  }

  try {
    const url = `${process.env.REACT_APP_API_URL}/generate/poll`;
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const publishPollApi = async (payload: object) => {
  let token = getAccessToken();

  try {
    const url = `${process.env.REACT_APP_API_URL}/poll/publish`;
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};
