import React, { useState, useEffect, useRef } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import DialogBox from "./PostsDialogBox";
import NotFoundPage from "./NopostError";
import { getAllScheduledPosts } from "./allPostHelper";
import { useSelector } from "react-redux";
import { getLinkedinEntity } from "./allPostHelper";
import EditPost from "./EditPost";
import avatar from "assets/img/avatars/user.png";
import { FaRegEdit } from "react-icons/fa";

const groupPostsByDate = (posts) => {
  return posts?.reduce((groups, post) => {
    const date = dayjs(post.createdAt).format("YYYY-MM-DD");
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(post);
    return groups;
  }, {});
};

const ScheduledPost = ({ scheduledPosts }) => {
  const [posts, setPosts] = useState(scheduledPosts);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [dialogEditData, setDialogEditData] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const userAuthentication = useSelector((state) => state.userAuthentication);
  console.log(userAuthentication);

  const observer = useRef();
  const lastPostElementRef = useRef();

  const handleReadMore = (id) => {
    handleOpenDialog(id);
  };

  const handleClick = (event, postId) => {
    setAnchorEl(event.currentTarget);
    setCurrentPostId(postId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentPostId(null);
  };

  const handleOpenDialog = (postId) => {
    let content = posts.find((d) => d.postId === postId);
    setDialogData(content);
    setOpenDialog(true);
    handleClose();
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const loadMorePosts = async () => {
    setLoading(true);
    try {
      const response = await getAllScheduledPosts(page + 1, 10);
      const newPosts = response.data?.posts || [];

      setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      setPage((prevPage) => prevPage + 1);

      if (newPosts?.length <= 10) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenEditDialog = (postId) => {
    let content = posts.find((d) => d.postId === postId);
    setDialogEditData(content);
    setOpenEditDialog(true);
    handleClose();
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const callback = (entries) => {
      if (
        entries[0].isIntersecting &&
        !loading &&
        hasMore &&
        posts.length > 10
      ) {
        loadMorePosts();
      }
    };

    observer.current = new IntersectionObserver(callback, options);

    if (lastPostElementRef.current) {
      observer.current.observe(lastPostElementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, hasMore]); // eslint-disable-line react-hooks/exhaustive-deps

  if (posts?.length === 0) {
    return <NotFoundPage />;
  }

  const groupedPosts = groupPostsByDate(posts);
  const contentLimit = 105;

  return (
    <>
      {Object?.keys(groupedPosts).map((date) => (
        <div key={date} className="my-4 rounded-lg border p-4">
          <h2 className="text-lg font-light">
            {dayjs(date).format("MMMM D, YYYY")}
          </h2>
          {groupedPosts[date].map((post, idx) => {
            const displayedContent = `${post.content.substring(
              0,
              contentLimit
            )}...`;

            const isLastPost =
              idx === groupedPosts[date].length - 1 &&
              date === Object.keys(groupedPosts).slice(-1)[0];

            return (
              <div
                className="mt-3 space-y-4 rounded border p-2"
                key={idx}
                ref={isLastPost ? lastPostElementRef : null}
              >
                <div className="flex h-6 items-center justify-between">
                  <div className="flex items-center">
                    <span className="ml-2 text-gray-700">
                      {post.createdAt
                        ? dayjs(post.createdAt).format("hh:mm A")
                        : ""}
                    </span>
                  </div>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, post.postId)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl) && currentPostId === post.postId}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: 200,
                        width: "13ch",
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleOpenDialog(post.postId)}>
                      <button className="flex items-center justify-center text-gray-700 hover:text-gray-500">
                        <MdOutlineRemoveRedEye className="text-1xl mr-2" />
                        Preview
                      </button>
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenEditDialog(post.postId)}>
                      <button className="flex items-center justify-center text-gray-700 hover:text-gray-500">
                        <FaRegEdit className="text-1xl mr-2" />
                        Edit
                      </button>
                    </MenuItem>
                  </Menu>
                </div>

                <div
                  style={{ marginTop: "3px" }}
                  className="border-slate-600 mt-2 flex flex-row space-y-2 rounded-md bg-[#F8F8F8] pb-1 pl-2 pr-2 pt-1 sm:space-x-4 sm:space-y-0"
                >
                  <span className="flex items-center text-green-600">
                    <img
                      className="mr-2"
                      src={
                        getLinkedinEntity(
                          userAuthentication.linkedinUsers,
                          post.entityId,
                          post.entityType
                        ) || avatar
                      }
                      alt="Preview"
                      style={{
                        height: "35px",
                        width: "35px",
                        borderRadius: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </span>
                  <span
                    style={{ maxWidth: "90%" }}
                    className="flex flex-1 flex-col flex-wrap p-1 text-sm text-gray-800"
                  >
                    {displayedContent.split("\n").map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                    <div>
                      <button
                        onClick={() => handleReadMore(post.postId)}
                        className="text-blue-700"
                      >
                        See more
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ))}

      {loading && <p>Loading more posts...</p>}

      <DialogBox
        dialogData={dialogData}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        hideEdit={false}
      />
      <EditPost
        dialogEditData={dialogEditData}
        openDialog={openEditDialog}
        handleCloseDialog={handleCloseEditDialog}
      />
    </>
  );
};

export default ScheduledPost;
