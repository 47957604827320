import { motion } from "framer-motion";
import { ReactNode, FC } from "react";

interface ItemLayoutProps {
  children: ReactNode;
}

const LeftToRightMotion: FC<ItemLayoutProps> = ({ children }) => {
  return (
    <motion.div
      initial={{ x: -100, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      className="custom-bg flex items-center justify-center space-y-8 rounded-xl p-6 sm:p-8"
    >
      {children}
    </motion.div>
  );
};

export default LeftToRightMotion;
