import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FaTrash,
  FaPlus,
  FaUser,
  FaEnvelope,
  FaLinkedin,
  FaCalendarAlt,
  FaEllipsisV,
} from "react-icons/fa";
import {
  getAccessToken,
  LogOutLinkedIn,
  getUserInfo,
} from "views/auth/AuthHelper";
import NewToast from "components/ToastMessges/NewToast";
import { UserData } from "Store/loginController";
import { getConnectUrl } from "views/quickpost/QuickPostHelper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import BottomToTopMotion from "components/Motions/BottomToTopMotion";

interface LinkedinOrganization {
  linkedinOrganizationId: number;
  organizationName: string;
  localizedName: string;
  localizedWebsite: string | null;
  organizationLogo: string | null;
}

interface LinkedinUser {
  linkedinUserId: number;
  name: string;
  email: string;
  profileUrl: string | null;
  linkedinOrganizations: LinkedinOrganization[];
}

interface UserAuthentication {
  id: number;
  name: string;
  email: string;
  isEmailVerified: boolean;
  role: string;
  companyName: string | null;
  createdAt: string;
  linkedinUsers: LinkedinUser[];
  isLinkedinConnected: boolean;
}

const UserProfile: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const phoneScreens = useMediaQuery(theme.breakpoints.up("sm"));
  const [toast, setToast] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const userAuthentication: UserAuthentication = useSelector(
    (state: any) => state.userAuthentication
  );
  const isAuthenticated = getAccessToken();

  const handleConfirmDialog = (id: number) => {
    setSelectedUserId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
    setAnchorEl(null); // Close the menu when dialog closes
  };

  const handleLogOutLinkedIn = async () => {
    if (selectedUserId !== null) {
      localStorage.setItem("linkedInSkip", "true");
      let response = await LogOutLinkedIn(isAuthenticated, selectedUserId);
      if (response.success) {
        setToast({
          message: response.message || "LinkedIn Account Removed",
          type: "success",
        });
        const res = await getUserInfo(isAuthenticated);
        dispatch(UserData(res?.data));
      } else {
        setToast({
          message: response.message || "Something Went Wrong",
          type: "error",
        });
      }
      handleCloseDialog();
    }
  };

  const handleAddAccount = () => {
    let res = getConnectUrl();
    window.open(res, "_self");
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    userId: number
  ) => {
    event.stopPropagation(); // Prevent Accordion from expanding/collapsing
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  return (
    <>
      {toast && (
        <NewToast
          message={toast.message}
          type={toast.type}
          vertical={"top"}
          horizontal={"right"}
          onClose={() => setToast(null)}
        />
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this account?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogOutLinkedIn} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleConfirmDialog(selectedUserId)}>
          <FaTrash className="text-red-500" style={{ marginRight: "10px" }} />
          Delete
        </MenuItem>
      </Menu>

      <BottomToTopMotion>
        <div className="scrollbar mx-auto max-w-full p-2 sm:p-8">
          {/* User Information */}
          <div className="mb-6 rounded-lg bg-white p-4 shadow-lg sm:p-6">
            <h1 className="mb-4 text-lg font-bold sm:mb-6 sm:text-2xl">
              User Profile
            </h1>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
              <p className="flex items-center">
                <FaUser className="mr-2" />
                <span className="mr-2 font-semibold">Name: </span>{" "}
                {userAuthentication.name}
              </p>
              <p className="flex items-center">
                <FaEnvelope className="mr-2" />
                <span className="mr-2 font-semibold">Email: </span>{" "}
                {userAuthentication.email}
              </p>
              <p className="flex items-center">
                <FaLinkedin className="mr-2" />
                <span className="mr-2 font-semibold">
                  LinkedIn Connected:{" "}
                </span>{" "}
                {userAuthentication.isLinkedinConnected ? "Yes" : "No"}
              </p>
              <p className="flex items-center">
                <FaCalendarAlt className="mr-2" />
                <span className="mr-2 font-semibold">Member Since: </span>{" "}
                {new Date(userAuthentication.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>

          {/* LinkedIn Accounts Section */}
          <div className="mb-6 rounded-lg bg-white p-4 shadow-lg sm:p-6">
            <div className="mb-4 flex items-center justify-between sm:mb-6">
              <h2 className="text-lg font-bold sm:text-2xl">
                LinkedIn Accounts
              </h2>
              <Button
                onClick={handleAddAccount}
                variant="contained"
                color="primary"
                className="flex items-center justify-center"
                style={{ background: "#6366F1" }}
                startIcon={
                  <FaPlus
                    style={{
                      fontSize: "1rem",
                      minWidth: "2rem",
                      display: "flex",
                      justifyContent: "center",
                      padding: "0",
                    }}
                  />
                }
              >
                <span>{phoneScreens ? "Add Account" : "Add"}</span>
              </Button>
            </div>

            <div className="space-y-4">
              {userAuthentication.linkedinUsers.map((user) => (
                <Accordion key={user.linkedinUserId}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${user.linkedinUserId}-content`}
                    id={`panel-${user.linkedinUserId}-header`}
                    className="bg-gray-100"
                  >
                    <div className="flex w-full items-center">
                      <div className="mr-4 h-12 w-12 sm:h-16 sm:w-16">
                        {user.profileUrl ? (
                          <img
                            src={user.profileUrl}
                            alt={user.name}
                            className="h-full w-full rounded-full object-cover"
                          />
                        ) : (
                          <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-300 text-white">
                            N/A
                          </div>
                        )}
                      </div>
                      <div className="flex-1">
                        <Typography
                          variant="h6"
                          className="text-sm sm:text-base sm:font-bold"
                        >
                          {user.name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className="text-xs sm:text-sm"
                        >
                          {user.email}
                        </Typography>
                      </div>
                      <div className="ml-2 sm:ml-4">
                        <IconButton
                          onClick={(event) =>
                            handleMenuClick(event, user.linkedinUserId)
                          }
                          size="small"
                        >
                          <FaEllipsisV
                            className="text-gray-500"
                            style={{ fontSize: "1.25rem" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="bg-gray-50">
                    {user.linkedinOrganizations.length > 0 ? (
                      <div>
                        <h3 className="mb-2 font-semibold sm:mb-3">
                          LinkedIn Pages
                        </h3>
                        <div className="space-y-2 sm:space-y-4">
                          {user.linkedinOrganizations.map((org) => (
                            <div
                              key={org.linkedinOrganizationId}
                              className="flex items-center rounded-lg bg-white p-4 shadow-md"
                            >
                              <div className="mr-4 h-12 w-12 sm:h-16 sm:w-16">
                                {org.organizationLogo ? (
                                  <img
                                    src={org.organizationLogo}
                                    alt={org.localizedName}
                                    className="h-full w-full rounded-full object-cover"
                                  />
                                ) : (
                                  <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-300 text-white">
                                    N/A
                                  </div>
                                )}
                              </div>
                              <div className="flex-1 text-sm sm:text-base">
                                <p className="font-semibold">
                                  {org.localizedName}
                                </p>
                                {org.localizedWebsite && (
                                  <a
                                    href={org.localizedWebsite}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:underline"
                                  >
                                    Visit Website
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Typography variant="body2" className="text-gray-500">
                        No linked pages.
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </BottomToTopMotion>
    </>
  );
};

export default UserProfile;
