import { Dialog, DialogActions, Button } from "@mui/material";
import { FcLike } from "react-icons/fc";
import { MdOutlineClose } from "react-icons/md";
import dayjs from "dayjs";
import React, { useRef, useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import Buttons from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const EditPost = ({ dialogEditData, openDialog, handleCloseDialog }) => {
  const [content, setContent] = useState(dialogEditData?.content || "");
  const saveRef = useRef(null);

  useEffect(() => {
    setContent(dialogEditData?.content || "");
  }, [dialogEditData]);

  const handleSaveOnChange = (e) => {
    setContent(e.target.value);
  };

  const handleSaveEditedContent = () => {
    
    if (saveRef.current) {
      dialogEditData.content = saveRef.current.value;
    }
    handleCloseDialog(); // Assuming you want to close the dialog after saving
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      style={{ margin: "auto", width: "100%", maxWidth: "600px" }}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          height: "auto",
          borderRadius: "8px",
        },
      }}
    >
      <div className="m-2 overflow-hidden rounded border p-4 sm:m-3">
        <div className="mb-4 flex items-center">
          <div
            className="mr-2 h-10 w-10 rounded-full bg-cover bg-center"
            style={{
              backgroundImage: `url(${dialogEditData?.imageUrl})`,
            }}
          ></div>
          <div>
            <p className="text-xs font-semibold sm:text-sm">User</p>
            <p className="text-xs text-gray-500 sm:text-sm">
              Software Engineer
            </p>
            <p className="text-xs text-gray-400 sm:text-sm">
              {dialogEditData?.createdAt
                ? dayjs(dialogEditData.createdAt).format("hh:mm A")
                : ""}
            </p>
          </div>
          <Stack
            direction="row"
            spacing={2}
            style={{ position: "absolute", right: "15%", top: "4%" }}
          >
            <Button
              variant="outlined"
              startIcon={<SaveIcon />}
              onClick={handleSaveEditedContent}
            >
              Save
            </Button>
          </Stack>
          <DialogActions
            className="flex items-end "
            style={{ position: "absolute", right: "0%", top: "3%" }}
          >
            <div></div>
            <Buttons onClick={handleCloseDialog} color="primary">
              <MdOutlineClose className="text-xl sm:text-2xl" />
            </Buttons>
          </DialogActions>
        </div>

        <div className="mb-4 whitespace-pre-wrap text-xs text-gray-700 sm:text-sm">
          <textarea
            cols={65}
            rows={20}
            value={content}
            ref={saveRef}
            onChange={handleSaveOnChange}
          ></textarea>
        </div>

        <div className="border-t border-gray-300 pb-0 pt-2">
          <div className="flex items-center justify-between py-1 text-xs text-gray-500 sm:text-sm">
            <div className="flex items-center space-x-4 text-xs sm:text-sm">
              <div className="flex items-center">
                <FcLike className="mr-1" /> 450 Likes
              </div>
              <div className="flex items-center">
                <i className="fas fa-comment-alt mr-2"></i> 108 comments
              </div>
              <div className="flex items-center">
                <i className="fas fa-share mr-2"></i> 4 shares
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditPost;
