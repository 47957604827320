import React, { useState, useEffect, useRef } from "react";
import postReaction from "../../assets/svg/post-reactions.svg";
import avatar from "assets/img/avatars/user.png";
import Skeleton from "@mui/material/Skeleton";
import BottomToTopMotion from "components/Motions/BottomToTopMotion";
import { FiEdit } from "react-icons/fi";
import { FaRegCopy } from "react-icons/fa";
import ImageComponent from "./ImageComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface LinkedInPostCardProps {
  contentLoading: boolean;
  generateContentData: string | null;
  isWritingFinished: any;
  setIsWritingFinished: any;
  setContentGeneratingLoading: any;
  setGenerateContentData: any;
  SelectedId: any;
  CopygenerateImage: any;
}

const LinkedInPostCard: React.FC<LinkedInPostCardProps> = ({
  contentLoading,
  generateContentData,
  setIsWritingFinished,
  isWritingFinished,
  setContentGeneratingLoading,
  setGenerateContentData,
  SelectedId,
  CopygenerateImage,
}) => {
  const headline = "Software Engineer";
  const likes = 450;
  const comments = 108;
  const shares = 4;
  const [randomQuote, setRandomQuote] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const skipwriting = localStorage.getItem("skipwriting") === "true";
  const theme = useTheme();
  // const laptopScreens = useMediaQuery(theme.breakpoints.up("lg"));
  // const tabletScreens = useMediaQuery(theme.breakpoints.up("md"));
  const phoneScreens = useMediaQuery(theme.breakpoints.up("sm"));

  const quotes = [
    "Content builds relationships. Relationships are built on trust. Trust drives revenue. – Andrew Davis",
    "People do not buy goods and services. They buy relations, stories, and magic. – Seth Godin",
    "The best marketing doesn't feel like marketing. – Tom Fishburne",
    "Content is the atomic particle of all digital marketing. – Rebecca Lieb",
    "Your brand is a story unfolding across all customer touch points. – Jonah Sachs",
    "Marketing is no longer about the stuff that you make, but about the stories you tell. – Seth Godin",
    "The future of marketing is about being helpful. – Jay Baer",
    "Content is anything that adds value to the reader's life. – Avinash Kaushik",
    "Good content isn't about good storytelling. It's about telling a true story well. – Ann Handley",
    "You can never go wrong by investing in communities and the human beings within them. – Pam Moore",
    "Content is King, but engagement is Queen, and the lady rules the house! – Mari Smith",
    "Content: there is no easy button. – Scott Abel",
    "Creating content that serves the needs of your audience is the only way to build a brand that lasts. – John Hall",
    "Quality content means being authentic, not perfect. – Ann Handley",
    "Your content leads to conversation. – Barry Feldman",
  ];
  if (contentRef?.current && contentLoading) {
    contentRef.current.innerHTML = "";
  }

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);

    if (contentRef.current || contentLoading) {
      contentRef.current.innerHTML = "";
    }
    if (generateContentData) {
      setContentGeneratingLoading(true);
      let currentIndex = 0;

      if (skipwriting) {
        if (contentRef.current) {
          contentRef.current.innerHTML = generateContentData
            .split("\n")
            .join("<br/>");
        }
        setIsWritingFinished(true);
        setContentGeneratingLoading(false);
      } else {
        const typingInterval = setInterval(() => {
          const nextChar = generateContentData[currentIndex];
          const updatedText = nextChar === "\n" ? "<br/>" : nextChar;
          if (contentRef.current) {
            contentRef.current.innerHTML += updatedText;
          }

          currentIndex++;
          if (currentIndex >= generateContentData.length) {
            clearInterval(typingInterval);
            setIsWritingFinished(true);
            setContentGeneratingLoading(false);
          }

          if (autoScroll && scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
              scrollContainerRef.current.scrollHeight;
          }
        }, 8);

        return () => clearInterval(typingInterval);
      }
    }
  }, [generateContentData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleScroll = () => {
      console.log("User scrolled!");
      setAutoScroll(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const splitQuote = randomQuote.split(" – ");

  const saveChanges = () => {
    let output = contentRef.current?.innerText; //?.replace(/\n/g, "<br/>");
    console.log("Save changes function called", output);
    setGenerateContentData(output);
    setEditMode(false);
  };

  const handleCopy = () => {
    if (contentRef.current) {
      const textToCopy = contentRef.current.innerText;
      navigator?.clipboard?.writeText(textToCopy).then(
        () => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 2000);
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    }
  };

  const renderPostContent = () => {
    if (contentLoading) {
      return (
        <div className="mb-4">
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="90%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
        </div>
      );
    } else if (!generateContentData) {
      return (
        <div className="mt-[16rem] text-center text-gray-500">
          <span className="block text-lg">{splitQuote[0]}</span>
          <span className="mt-2 block text-sm font-bold">
            – {splitQuote[1]}
          </span>
        </div>
      );
    } else {
      return (
        <>
          <div
            className={`mb-4 p-1 ${editMode && "border"}`}
            contentEditable={editMode}
            ref={contentRef}
            suppressContentEditableWarning={true}
          />
          <ImageComponent
            generateContentData={generateContentData}
            parentGenerateImage={CopygenerateImage}
            skipLoading={skipwriting}
          />
        </>
      );
    }
  };

  if (!contentLoading && !generateContentData) {
    return (
      <div className="text-center text-gray-500">{renderPostContent()}</div>
    );
  }

  return (
    <BottomToTopMotion>
      <div
        className="scrollbar mx-auto my-4 max-w-lg rounded-lg border border-gray-300 bg-white p-4 shadow-lg sm:max-h-[60vh] sm:min-w-[20rem] md:max-h-[75vh] md:min-w-[25rem]"
        ref={scrollContainerRef}
        style={{
          maxHeight: phoneScreens ? "75vh" : "60vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* LinkedIn Post Card Header */}
        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={SelectedId?.imageUrl || avatar}
              alt="Profile"
              className="mr-4 h-12 w-12 rounded-full"
            />
            <div>
              <h3 className="text-lg font-semibold">
                {SelectedId?.name || "User"}
              </h3>
              <p className="text-sm text-gray-600">{headline}</p>
              <p className="text-xs text-gray-400">Just now</p>
            </div>
          </div>
          {isWritingFinished && !contentLoading && (
            <div className="mr-[-1.2rem] flex h-[10%] items-center space-x-2 px-2 ">
              <span
                onClick={handleCopy}
                className="inline-flex items-center justify-center rounded-lg p-3 text-base font-medium hover:bg-gray-200 hover:text-gray-900"
              >
                <FaRegCopy size={18} className="mr-2" />
                <span>{copySuccess ? "Copied!" : "copy"}</span>
              </span>
              <div
                className="divide-black divide-y-4 divide-opacity-25 border-l-2 pl-2"
                onClick={() => {
                  if (editMode) {
                    saveChanges();
                  } else {
                    setEditMode(true);
                  }
                }}
              >
                {editMode ? (
                  <span className="rounded-lg bg-indigo-600 px-5 py-2.5 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-4 focus:ring-indigo-300">
                    Save
                  </span>
                ) : (
                  <span className="inline-flex items-center justify-center rounded-lg p-3 text-base font-medium hover:bg-gray-200 hover:text-gray-900">
                    <FiEdit size={18} className="mr-2" />
                    <span>Edit</span>
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Post Content */}
        {renderPostContent()}
        <div className="mt-4 flex justify-between text-sm text-gray-600">
          <div className="flex items-center">
            <img src={postReaction} alt="Reactions" className="mr-2 h-4 w-10" />
            <span>{likes} likes</span>
          </div>
          <div className="flex space-x-4">
            <span>{comments} comments</span>
            <span>{shares} shares</span>
          </div>
        </div>
      </div>
    </BottomToTopMotion>
  );
};

export default LinkedInPostCard;
