import { Dialog, DialogActions, Button } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import { getLinkedinEntity } from "./allPostHelper";
import avatar from "assets/img/avatars/user.png";

const DialogBox = ({ dialogData, openDialog, handleCloseDialog }) => {
  const userAuthentication = useSelector((state) => state.userAuthentication);
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      style={{ margin: "auto", width: "100%", maxWidth: "600px" }}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          height: "auto",
          borderRadius: "8px",
        },
      }}
    >
      <div className="scrollbar m-2 overflow-auto rounded border p-4 sm:m-3">
        <div className="mb-4 flex items-center">
          <div
            className="mr-2 h-10 w-10 rounded-full bg-cover bg-center"
            style={{
              backgroundImage: `url(${
                getLinkedinEntity(
                  userAuthentication.linkedinUsers,
                  dialogData.entityId,
                  dialogData.entityType
                ) || avatar
              })`,
            }}
          ></div>
          <div>
            <p className="text-xs font-semibold sm:text-sm">User</p>
            <p className="text-xs text-gray-500 sm:text-sm">
              Software Engineer
            </p>
            <p className="text-xs text-gray-400 sm:text-sm">
              {dialogData?.createdAt
                ? dayjs(dialogData.createdAt).format("hh:mm A")
                : ""}
            </p>
          </div>
          <DialogActions
            className="flex items-end "
            style={{ position: "absolute", right: "0%", top: "3%" }}
          >
            <div></div>
            <Button onClick={handleCloseDialog} color="primary">
              <MdOutlineClose className="text-xl sm:text-2xl" />
            </Button>
          </DialogActions>
        </div>

        <div className="mb-4 whitespace-pre-wrap text-xs text-gray-700 sm:text-sm">
          {dialogData.content &&
            dialogData.content.split("\n").map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
        </div>
        {dialogData?.imageUrl && (
          <div className="relative mb-[1rem] h-64 w-auto">
            <>
              <img
                src={dialogData?.imageUrl ?? ""}
                alt="Loaded content"
                className="h-full w-full rounded-lg object-cover"
              />
            </>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DialogBox;
