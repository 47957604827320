import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const Header = createSlice({
  name: "userAuthentication",
  initialState,
  reducers: {
    UserData: (state, action) => {
      const { payload } = action;
      return { ...state, ...payload };
    },
  },
});

export default Header.reducer;
export const { UserData } = Header.actions;
