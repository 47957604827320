import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const FullScreenLoader = () => {
  return (
    <Backdrop open={true} style={{ color: "#fff", zIndex: 1201 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default FullScreenLoader;
