import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ContentState {
  usecaseId: string;
  context: string;
  type: string;
  keywords: string[];
  targetAudience: string;
  tone: string;
  language: string;
  cta: string;
  length: string;
}

const initialState: ContentState = {
  usecaseId: "post-creation",
  context: "",
  type: "",
  keywords: [],
  targetAudience: "",
  tone: "Formal",
  language: "English",
  cta: "",
  length: "short",
};
const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    updateContext: (state, action: PayloadAction<Partial<ContentState>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { updateContext } = contentSlice.actions;
export default contentSlice.reducer;
