import React from "react";
import emailVerification from "../../assets/svg/mailSent.svg";
import { useNavigate } from "react-router-dom";
import LeftToRightMotion from "components/Motions/LeftToRightMotion";

const EmailVerification: React.FC = () => {
  const navigate = useNavigate();
  let email = localStorage.getItem("loginEmail");
  if (!email) {
    navigate("/auth/linkedin-signup");
  }
  return (
    <LeftToRightMotion>
      <div className="mt-[3rem] flex justify-center">
        <div className="border-t-1 max-w-2xl rounded-xl  bg-white p-8 transition duration-200 dark:bg-navy-700">
          <div className="text-center">
            <h2 className="mb-4 text-2xl font-semibold  dark:text-white">
              Verify your email
            </h2>
            <p className="mb-8 text-navy-700 dark:text-white">
              You will need to verify your email to complete registration
            </p>
            <img
              src={emailVerification}
              alt="Verify Email"
              className="mx-auto h-[15rem]"
            />
            <p className="mb-6 mt-[1rem] text-navy-700 dark:text-white">
              An email has been sent to{" "}
              <span className="font-semibold">{email}</span> with a link to
              verify your account. If you have not received the email after a
              few minutes, please check your spam folder.
            </p>
            <div className="flex justify-center space-x-4">
              {/* <button className="rounded bg-[#5334FF] px-4 py-2 text-white hover:bg-blue-600">
              Resend Email
            </button> */}
              {/* <button className="rounded border border-blue-500 px-4 py-2 text-blue-500 hover:bg-blue-50">
              Contact Support
            </button> */}
            </div>
          </div>
        </div>
      </div>
    </LeftToRightMotion>
  );
};

export default EmailVerification;
