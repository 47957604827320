import { motion } from "framer-motion";
import React, { ReactNode, FC } from "react";

interface AnimatedMotionProps {
  children: ReactNode;
  keyProp?: string;
  className?: string;
}

const BottomToTopMotion: FC<AnimatedMotionProps> = ({
  children,
  keyProp,
  className,
}) => {
  return (
    <motion.div
      key={keyProp}
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default BottomToTopMotion;
