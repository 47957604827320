import React, { useState, useEffect, useRef } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { getAllScheduledpoll } from "./allPostHelper";
import PollDialogBox from "./PollDialogBox";
import dayjs from "dayjs";
import avatar from "assets/img/avatars/user.png";
import { useSelector } from "react-redux";
import { getLinkedinEntity } from "./allPostHelper";

const ScheduledPoll = () => {
  const [polls, setPolls] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPollId, setCurrentPollId] = useState(null);
  const [dialogPoll, setDialogPoll] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMorePolls, setHasMorePolls] = useState(true);
  const [page, setPage] = useState(1);
  const userAuthentication = useSelector((state) => state.userAuthentication);
  console.log(userAuthentication);

  const observer = useRef();
  const lastPollElementRef = useRef();

  // Load more posts by calling the API
  const loadMorePolls = async () => {
    setLoading(true);
    try {
      const response = await getAllScheduledpoll(page, 10);
      const newPolls = response?.data?.polls || [];
      setPolls((prevPolls) => [...prevPolls, ...newPolls]);
      setPage(page + 1);
      if (newPolls.length < 10) {
        setHasMorePolls(false);
      }
    } catch (error) {
      console.error("Error loading more polls:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMorePolls();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Intersection Observer to handle infinite scrolling
  useEffect(() => {
    const intersectCallback = (entries) => {
      if (entries[0].isIntersecting && !loading && hasMorePolls) {
        loadMorePolls();
      }
    };

    observer.current = new IntersectionObserver(intersectCallback);
    if (lastPollElementRef.current) {
      observer.current.observe(lastPollElementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, hasMorePolls]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event, pollId) => {
    setAnchorEl(event.currentTarget);
    setCurrentPollId(pollId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentPollId(null);
  };

  const handleOpenDialog = (pollId) => {
    const selectedPoll = polls.find((poll) => poll.pollId === pollId);
    setDialogPoll(selectedPoll);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setDialogPoll(null);
  };

  if (!polls.length && !loading) {
    return <p>No scheduled polls available</p>;
  }

  return (
    <>
      {polls.map((poll, idx) => (
        <div
          key={poll.pollId}
          className="my-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm"
          ref={
            idx === polls.length - 1 && hasMorePolls ? lastPollElementRef : null
          }
        >
          <div className="flex items-center justify-between">
            <p className="text-sm text-gray-500">
              {dayjs(poll.scheduledAt).format("hh:mm A")}
            </p>
            <IconButton onClick={(event) => handleClick(event, poll.pollId)}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl && currentPollId === poll.pollId}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => handleOpenDialog(poll.pollId)}>
                <MdOutlineRemoveRedEye className="mr-2" /> Preview
              </MenuItem>
            </Menu>
          </div>

          <div className="mt-2 flex items-start">
            <img
              src={
                getLinkedinEntity(
                  userAuthentication.linkedinUsers,
                  poll.entityId,
                  poll.entityType
                ) || avatar
              }
              className="mr-3 h-10 w-10 rounded-full"
              alt="Avatar"
            />
            <div className="flex-1">
              <h3 className="text-md font-semibold">{poll.content}</h3>
              <p className="text-md mt-2">{poll.question}</p>
              <button
                className="mt-2 text-blue-500 hover:underline"
                onClick={() => handleOpenDialog(poll.pollId)}
              >
                See more
              </button>
            </div>
          </div>
        </div>
      ))}
      {loading && <p>Loading more polls...</p>}
      {dialogPoll && (
        <PollDialogBox poll={dialogPoll} onClose={handleCloseDialog} />
      )}
    </>
  );
};

export default ScheduledPoll;
