import React from "react";
import { SiCodemagic } from "react-icons/si";
import { useSelector, useDispatch } from "react-redux";
import { setReGenerateImage } from "Store/ImageSlice";

// Define TypeScript Interface Props
interface PublishButtonProps {
  generateImage: boolean;
  isDrawer: boolean;
}

const ImageReGenerateButton: React.FC<PublishButtonProps> = ({
  generateImage,
  isDrawer,
}) => {
  // Initiate Dispatch
  const dispatch = useDispatch();

  // Selectors from Redux Store
  const { isLoading, imageUrl, regenerateImage, isRemoveImageCliked } =
    useSelector((state: any) => state.image);
  const publishButtonLoading = useSelector(
    (state: any) => state.publishButtonLoading?.isLoading
  );

  const isDisabled = isLoading || imageUrl || publishButtonLoading;

  // Click Handler Function
  const handleReGenerateImage = () => {
    localStorage.setItem("skipwriting", "false");
    dispatch(setReGenerateImage(!regenerateImage));
  };

  // Return Early if button should not render
  if (!generateImage || !isRemoveImageCliked) {
    return null;
  }

  return (
    <div className={`${isDrawer && "mx-auto w-2/3"}`}>
      <button
        id="dropdownInformationButton"
        data-dropdown-toggle="dropdownInformation"
        className={`${
          isDisabled && "cursor-not-allowed opacity-50"
        } inline-flex w-full items-center justify-center rounded bg-gradient-to-br from-purple-600 to-blue-500 px-5 py-2 text-sm font-medium text-white hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
        type="button"
        onClick={handleReGenerateImage}
        disabled={isDisabled}
        style={{ borderRadius: "4px" }}
      >
        <SiCodemagic className="mr-2" size={20} />
        Re Generate Image
      </button>
    </div>
  );
};

export default ImageReGenerateButton;
