import React, { useState } from "react";
import InputField from "components/input/InputField";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import authImg from "../../../src/assets/svg/auth.svg";
import {
  UserSignUp,
  UserSignIn,
  googleSignUp,
  setAccessToken,
} from "./AuthHelper";
import FullScreenLoader from "components/FullScreenLoader";
import RightToLeftMotion from "components/Motions/RighttoLeftMotion";
import NewToast from "components/ToastMessges/NewToast";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

type FormValues = {
  name: string;
  email: string;
  password: string;
};

type Errors = {
  name: string;
  email: string;
  password: string;
};

const SignIn = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<Errors>({
    name: "",
    email: "",
    password: "",
  });
  const [toast, setToast] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormValues((prev) => ({ ...prev, [id]: value }));
    validateField(id, value);
    if (toast?.message) {
      setToast(null);
    }
  };

  const validateField = (field: string, value: string) => {
    let error = "";

    switch (field) {
      case "name":
        if (isSignUp && !value.trim()) {
          error = "Full Name isrequired";
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "Email is required";
        } else if (!emailRegex.test(value)) {
          error = "Email is invalid";
        }
        break;
      case "password":
        if (!value.trim()) {
          error = "Password is required";
        } else if (value.length < 6) {
          error = "Password must be at least 6 characters long";
        } else if (value.length > 20) {
          error = "Password must be no more than 20 characters long";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  };

  const validate = () => {
    const newErrors: any = {};
    if (isSignUp && !formValues.name.trim()) {
      newErrors.name = "Full Name is required";
    }
    if (!formValues.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formValues.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formValues.password.trim()) {
      newErrors.password = "Password is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      setLoading(true);
      if (isSignUp) {
        const res = await UserSignUp(formValues);
        if (res?.success) {
          localStorage.setItem("loginEmail", res?.data?.email);
          navigate("/auth/email-verification");
        } else if (res?.message) {
          setToast({ message: res?.message, type: "error" });
        }
      } else {
        const res = await UserSignIn(formValues);
        if (res?.success) {
          setAccessToken(res?.data?.accessToken);
          navigate("/auth");
        } else if (res?.message) {
          setToast({ message: res?.message, type: "error" });
        }
      }
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate("/auth/forgot-password");
  };

  const handleSignUpToggle = () => {
    setIsSignUp((prev) => !prev);
    setFormValues({ name: "", email: "", password: "" });
    setErrors({ name: "", email: "", password: "" });
  };

  const googleLogin = async () => {
    setLoading(true);
    let res = await googleSignUp();
    if (res?.redirectUrl) {
      window.open(res?.redirectUrl, "_self");
    }
    setLoading(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="flex h-[85vh] w-full flex-col items-center justify-center lg:flex-row">
        {loading && (
          <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
            <FullScreenLoader />
          </div>
        )}
        <RightToLeftMotion
          keyProp={isSignUp.toString()}
          className="w-full max-w-[550px] flex-col items-center lg:w-[50%]"
        >
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            {isSignUp ? "Sign Up" : "Sign In"}
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            {isSignUp
              ? "Enter your details to create an account!"
              : "Enter your email and password to sign in!"}
          </p>

          {isSignUp && (
            <InputField
              variant="outlined"
              extra="mb-3"
              label="Full Name*"
              placeholder="John Doe"
              id="name"
              type="text"
              value={formValues.name}
              onChange={handleChange}
              error={errors.name}
              onKeyDown={handleKeyDown}
            />
          )}

          <InputField
            variant="outlined"
            extra="mb-3"
            label="Email*"
            placeholder="mail@PostSensy.com"
            id="email"
            type="text"
            value={formValues.email}
            onChange={handleChange}
            error={errors.email}
            onKeyDown={handleKeyDown}
          />

          <InputField
            variant="outlined"
            extra="mb-3"
            label="Password*"
            placeholder="password"
            id="password"
            type="password"
            value={formValues.password}
            onChange={handleChange}
            error={errors.password}
            onKeyDown={handleKeyDown}
          />

          {!isSignUp && (
            <div className="mb-4 flex items-center justify-between px-2">
              <div className="flex items-center">
                {/* <Checkbox />
                <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                  Keep me logged In
                </p> */}
              </div>
              <span
                className="cursor-pointer text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </span>
            </div>
          )}

          <button
            onClick={handleSubmit}
            className="linear mt-2 w-full rounded-xl bg-brand-400 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            {isSignUp ? "Sign Up" : "Sign In"}
          </button>

          <div className="mb-6 mt-4 flex items-center gap-3">
            <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
            <p className="text-base text-gray-600 dark:text-white"> or </p>
            <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          </div>

          <div
            onClick={googleLogin}
            className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800"
          >
            <div className="rounded-full text-xl">
              <FcGoogle />
            </div>
            <h5 className="text-sm font-medium text-navy-700 dark:text-white">
              Sign In with Google
            </h5>
          </div>

          <div className="mt-4">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
              {isSignUp ? "Already registered?" : "Not registered yet?"}
            </span>
            <span
              className="cursor-pointer text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              onClick={handleSignUpToggle}
            >
              {isSignUp ? " Sign In" : " Create an account"}
            </span>
          </div>
        </RightToLeftMotion>

        <RightToLeftMotion
          keyProp="image"
          className="hidden h-[30rem] items-center justify-center lg:flex lg:w-[50%]"
        >
          <div
            className="h-full w-full bg-cover bg-center"
            style={{ backgroundImage: `url(${authImg})` }}
          />
        </RightToLeftMotion>

        {toast && toast.message && (
          <NewToast
            message={toast.message}
            type={toast.type}
            vertical={"top"}
            horizontal={"right"}
            onClose={() => setToast(null)}
          />
        )}
      </div>
      <div className="left-1/2 text-center">
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Get ready to expand your LinkedIn network with PostSensy — where
          creators and influencers thrive!
        </p>
      </div>
    </>
  );
};

export default SignIn;
