import React, { useState, useCallback } from "react";
import { MdOutlineScheduleSend } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { BiSend } from "react-icons/bi";
import ScheduleModal from "./ScheduleModal";
import { useSelector } from "react-redux";
import { Button, Menu, MenuItem, Divider } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface PublishButtonProps {
  isWritingFinished: boolean;
  isDrawer: boolean;
  publishPost: any;
  finalSavebuttonLoading: any;
}

const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop) => prop !== "isDrawer",
})<{ isDrawer: boolean }>(({ theme, isDrawer }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginBottom: theme.spacing(1),
    minWidth: isDrawer ? 300 : 167,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.grey[300]
        : "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const PublishButton: React.FC<PublishButtonProps> = ({
  isWritingFinished,
  isDrawer,
  publishPost,
  finalSavebuttonLoading,
}) => {
  const { isLoading } = useSelector((state: any) => state.image);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    handleClose();
  }, [handleClose]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const isButtonDisabled = !isWritingFinished || isLoading || finalSavebuttonLoading;
  const cursorStyle = isButtonDisabled ? "not-allowed" : "pointer";

  return (
    <span
      style={{
        cursor: cursorStyle,
        display: isDrawer ? "flex" : undefined,
        justifyContent: isDrawer ? "center" : undefined,
      }}
    >
      <Button
        className={`rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 ${
          isButtonDisabled ? "cursor-not-allowed opacity-50" : ""
        }`}
        variant="contained"
        disableElevation
        disabled={isButtonDisabled}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 0,
          color: "white",
          width: isDrawer ? "65%" : undefined,
          cursor: cursorStyle,
        }}
      >
        <Button
          onClick={publishPost}
          disabled={isButtonDisabled}
          style={{
            flex: 1,
            textTransform: "none",
            color: "white",
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        >
          <FaLinkedin className="mr-2" size={20} />
          {finalSavebuttonLoading ? "Posting..." : "Publish Now"}
        </Button>
        <Divider
          orientation="vertical"
          flexItem
          style={{ background: "white" }}
        />
        <Button
          onClick={handleClick}
          disabled={isButtonDisabled || isLoading}
          style={{
            minWidth: "36px",
            textTransform: "none",
            color: "white",
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
        >
          <KeyboardArrowDownIcon />
        </Button>
      </Button>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        isDrawer={isDrawer}
      >
        <MenuItem onClick={openModal} disableRipple>
          <MdOutlineScheduleSend className="mr-2 text-xs" size={25} />
          Schedule
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={publishPost}
          disableRipple
          disabled={!isWritingFinished}
        >
          <BiSend className="mr-2 text-lg" size={22} />
          Publish Now
        </MenuItem>
      </StyledMenu>

      {isModalOpen && (
        <ScheduleModal
          closeModal={closeModal}
          isOpen={isModalOpen}
          publishPost={publishPost}
        />
      )}
    </span>
  );
};

export default PublishButton;
