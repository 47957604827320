
interface Props {
  content: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage: string | null;
}

const TopicInput: React.FC<Props> = ({
  content,
  handleInputChange,
  errorMessage,
}) => {
  return (
    <div className="md:w-[100%]">
      <label className="mb-1 block text-sm font-medium text-gray-700">
        Topic
      </label>
      <input
        defaultValue={content.type}
        type="text"
        name="type"
        placeholder="Enter the topic here..."
        className="block w-full rounded-md border border-gray-300 p-2 text-sm"
        onChange={handleInputChange}
        required
      />
      {errorMessage && (
        <div className="mt-2 text-sm text-red-500">{errorMessage}</div>
      )}
    </div>
  );
};

export default TopicInput;
