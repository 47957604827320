import { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { useSelector } from "react-redux";

export default function LoginedInterComm() {
  const userAuthentication = useSelector(
    (state: any) => state.userAuthentication
  );

  function convertToTimestamp(dateString: string) {
    return new Date(dateString).getTime();
  }

  useEffect(() => {
    if (userAuthentication && userAuthentication.id) {
      Intercom({
        app_id: "xaulk7i7",
        user_id: userAuthentication.id.toString(),
        name: userAuthentication.name,
        email: userAuthentication.email,
        created_at: Math.ceil(
          convertToTimestamp(userAuthentication.createdAt) / 1000
        ),
      });
    }
  }, [userAuthentication]);

  return <span></span>;
}
