import React from "react";

interface Props {
  content: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CallToActionInput: React.FC<Props> = ({ content, handleInputChange }) => {
  return (
    <div style={{ marginTop: "10px" }}>
      <label className="mb-1 block text-sm font-medium text-gray-700">
        Call to action
      </label>
      <input
        defaultValue={content.cta}
        type="text"
        name="cta"
        placeholder="Example: Buy my course on www.udemy.com"
        className="block w-full rounded-md border border-gray-300 p-2 text-sm"
        onChange={handleInputChange}
      />
    </div>
  );
};

export default CallToActionInput;
