import React from "react";

interface LanguageSelectorProps {
  language: string;
  handleInputChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  language,
  handleInputChange,
}) => {
  return (
    <div className="sm:flex-1 md:w-[8rem] md:flex-none">
      <label className="mb-1 block text-sm font-medium text-gray-700">
        Language
      </label>
      <div className="relative">
        <select
          name="language"
          value={language}
          onChange={handleInputChange}
          className="block w-full appearance-none rounded-md border border-gray-300 p-2 pl-4 text-sm"
        >
          <option value="">Select</option>
          <option value="English">English</option>
          <option value="Spanish">Spanish</option>
          <option value="French">French</option>
          <option value="Chinese">Chinese</option>
          <option value="Hindi">Hindi</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center pl-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.292 7.53a1 1 0 011.414 0L10 10.83l3.294-3.3a1 1 0 011.412 1.42l-4 4a1 1 0 01-1.416 0l-4-4a1 1 0 010-1.42z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
