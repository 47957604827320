import React from "react";

interface LengthSelectorProps {
  length: string;
  setFormData: (value: (prevData: any) => any) => void;
}

const LengthSelector: React.FC<LengthSelectorProps> = ({
  length,
  setFormData,
}) => {
  const handleLengthChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      length: value,
    }));
  };

  const LengthButton: React.FC<{ value: string; index: number }> = ({
    value,
  }) => {
    const isSelected = length === value;
    const baseClasses =
      "w-full rounded-lg py-2 text-center text-sm font-medium transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500";
    const selectedClasses = "bg-indigo-500 text-white";
    const unselectedClasses =
      "bg-gray-200 text-gray-700 hover:bg-navy-50 hover:text-gray-700";

    return (
      <button
        className={`${baseClasses} ${
          isSelected ? selectedClasses : unselectedClasses
        }`}
        onClick={() => handleLengthChange(value)}
      >
        {value.charAt(0).toUpperCase() + value.slice(1)}
      </button>
    );
  };

  return (
    <div className="mb-4 mt-[-0.1rem] w-full">
      <label className="mb-2 block text-sm font-medium text-gray-700">
        Length
      </label>
      <div className="flex w-full gap-2">
        {["short", "medium", "long"].map((value, index) => (
          <LengthButton key={value} value={value} index={index} />
        ))}
      </div>
    </div>
  );
};

export default LengthSelector;
