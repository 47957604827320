import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyLikedIn, getUserInfo } from "./AuthHelper";
import { useDispatch } from "react-redux";
import { getAccessToken } from "./AuthHelper";
import FullScreenLoader from "components/FullScreenLoader";
import NewToast from "components/ToastMessges/NewToast";

const LinkedInVerify: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const isAuthenticated = getAccessToken();
  const [toast, setToast] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const token = urlObj.searchParams.get("code");
    const state = urlObj.searchParams.get("state");

    const verifyToken = async () => {
      try {
        setLoading(true);
        if (!token) {
          setError("No token provided");
          navigate("/error");
          return;
        }
        const response = await verifyLikedIn(token, state, isAuthenticated);
        if (response?.data?.success) {
          setToast({
            message: response?.data?.message || "Connected",
            type: "success",
          });
          await getUserInfo(isAuthenticated);
          setTimeout(() => {
            navigate("/home/default");
          }, 1000);
        } else {
          setToast({
            message: response?.data?.message || "Failed",
            type: "success",
          });
          navigate("/error");
        }
      } catch (err) {
        setError((err as Error)?.message || "An unexpected error occurred");
        navigate("/error");
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [dispatch, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <FullScreenLoader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    toast && (
      <NewToast
        message={toast.message}
        type={toast.type}
        vertical={"top"}
        horizontal={"right"}
        onClose={() => setToast(null)}
      />
    )
  );
};

export default LinkedInVerify;
