import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import BottomToTopMotion from "components/Motions/BottomToTopMotion";

export const SidebarLinks = (props: {
  routes: any;
  toggleSideBar: any;
}): JSX.Element => {
  let location = useLocation();
  const { routes, toggleSideBar } = props;

  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };
  const handleSideBar = () => {
    if (window.screen.width < 1200) {
      toggleSideBar();
    }
  };
  const createLinks = (routes: any) => {
    return routes?.map((route: any, index: any) => {
      if (route.layout === "/home") {
        const isDisabled = route.badge === "soon";
        return isDisabled ? (
          <div
            key={index}
            className={`relative flex cursor-not-allowed items-center opacity-50 transition-all duration-300 ease-in-out hover:ml-1 ${
              activeRoute(route.path)
                ? "text-black bg-[#DEE1EC]"
                : "hover:bg-gray-200"
            }`}
            style={{ padding: "10px 15px" }}
          >
            <span
              className={`${
                activeRoute(route.path)
                  ? "font-bold text-brand-500 dark:text-white"
                  : "font-medium text-gray-700"
              }`}
            >
              {route.icon ? route.icon : <DashIcon />}
            </span>
            <p
              className={`leading-1 ml-2 flex ${
                activeRoute(route.path)
                  ? "font-bold dark:text-white"
                  : "font-medium text-gray-700"
              }`}
            >
              {route.name}
            </p>
            {route.badge && (
              <span className="text-black absolute right-2 ml-2 rounded bg-[#eeeeee] px-2 py-1 text-xs font-semibold">
                {route.badge}
              </span>
            )}
          </div>
        ) : (
          <Link
            key={index}
            to={route.layout + "/" + route.path}
            onClick={handleSideBar}
          >
            <div
              className={`relative flex items-center px-[15px] py-[10px] transition-all duration-300 ease-in-out hover:ml-1 ${
                activeRoute(route.path)
                  ? "text-black bg-[#DEE1EC]"
                  : "hover:bg-gray-200"
              }`}
            >
              <span
                className={`${
                  activeRoute(route.path)
                    ? "text-black font-bold"
                    : "text-black font-medium"
                }`}
              >
                {route.icon ? route.icon : <DashIcon />}
              </span>
              <p
                className={`leading-1 ml-2 flex ${
                  activeRoute(route.path)
                    ? "font-bold dark:text-white"
                    : "font-medium text-gray-700"
                }`}
              >
                {route.name}
              </p>
              {route.badge && (
                <span className="text-slateblue absolute right-2 ml-2 rounded bg-[#eeeeee] px-2 py-1 text-xs font-semibold">
                  {route.badge}
                </span>
              )}
              {/* {activeRoute(route.path) && (
                <div className="absolute right-2 h-2 w-1 rounded-lg bg-gray-700 dark:bg-brand-400" />
              )} */}
            </div>
          </Link>
        );
      } else {
        return null; // Return null or appropriate JSX if the route layout is not "/home"
      }
    });
  };

  return (
    <BottomToTopMotion>
      <>{createLinks(routes)}</>
    </BottomToTopMotion>
  );
};

export default SidebarLinks;
