interface Props {
  content: any;
  handleInputChange: any;
  errorMessage: string | null;
  name: string;
  label: string;
}

const ContextInputField: React.FC<Props> = ({
  content,
  handleInputChange,
  errorMessage,
  name,
  label,
}) => {
  return (
    <div className="md:w-[100%]">
      <label className="mb-1 block text-sm font-medium text-gray-700">
        {label}
      </label>
      <textarea
        defaultValue={content.context}
        name={name}
        placeholder="Enter the topic here..."
        className="block w-full rounded-md border border-gray-300 p-2 text-sm"
        onChange={handleInputChange}
        required
        rows={4} // Adjust the number of rows as needed
      />
      {errorMessage && (
        <div className="mt-2 text-sm text-red-500">{errorMessage}</div>
      )}
    </div>
  );
};

export default ContextInputField;
