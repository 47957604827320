import { motion } from "framer-motion";
import React, { ReactNode, FC } from "react";

interface AnimatedMotionProps {
  children: ReactNode;
  keyProp?: string;
  className?: string;
}

const RightToLeftMotion: FC<AnimatedMotionProps> = ({
  children,
  keyProp,
  className,
}) => {
  return (
    <motion.div
      key={keyProp}
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={
        className ||
        "custom-bg flex items-center justify-center space-y-8 rounded-xl "
      }
    >
      {children}
    </motion.div>
  );
};

export default RightToLeftMotion;
