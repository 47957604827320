import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type DurationOption = {
  id: number;
  label: string;
  value: string;
};

const durationOptions: DurationOption[] = [
  { id: 1, label: "1 Day", value: "ONE_DAY" },
  { id: 2, label: "3 Days", value: "THREE_DAYS" },
  { id: 3, label: "1 Week", value: "ONE_WEEK" },
  { id: 4, label: "2 Weeks", value: "TWO_WEEKS" },
];

const StyledFormControl = styled(FormControl)({
  "& .MuiSelect-outlined": {
    padding: "7px",
  },
});

const StyledSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    minWidth: "150px",
    borderRadius: "0.375rem",
  },
  "& .MuiSelect-select": {
    paddingLeft: "13px",
  },
});

type DurationSelectorProps = {
  defaultValue?: string;
  setDuration?: any;
};

const DurationSelector: React.FC<DurationSelectorProps> = ({
  defaultValue,
  setDuration,
}) => {
  const [selectedOption, setSelectedOption] = useState<number>(() => {
    const defaultId =
      durationOptions.find((option) => option.value === defaultValue)?.id ?? 1;
    return defaultId;
  });

  useEffect(() => {
    const defaultId =
      durationOptions.find((option) => option.value === defaultValue)?.id ?? 1;
    setSelectedOption(defaultId);
  }, [defaultValue]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const option = durationOptions.find((opt) => opt.id === event.target.value);
    setDuration(option.value);
    setSelectedOption(event.target.value as number);
  };

  return (
    <StyledFormControl
      variant="outlined"
      className="w-auto items-center rounded-lg bg-[#F3F3F3] p-4 text-start text-sm font-medium text-[black] shadow-lg sm:p-6"
    >
      <StyledSelect
        value={selectedOption}
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        renderValue={(selected) => {
          if (selected === "") {
            return <span className="text-[black]">Select Duration</span>;
          }
          const option = durationOptions.find((opt) => opt.id === selected);
          return (
            <>
              {option?.label} <span className="hidden">{option?.value}</span>
            </>
          );
        }}
        className="text-[black]"
      >
        {durationOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label} <span className="hidden">{option.value}</span>
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default DurationSelector;
