import React from "react";

interface TargetAudienceProps {
  targetAudience: string;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

const TargetAudience: React.FC<TargetAudienceProps> = ({
  targetAudience,
  handleInputChange,
}) => {
  return (
    <div>
      <label className="mb-1 block text-sm font-medium text-gray-700">
        Target audience
      </label>
      <div className="relative">
        <select
          name="targetAudience"
          value={targetAudience}
          onChange={handleInputChange}
          className="block w-full appearance-none rounded-md border border-gray-300 p-2 pr-8 text-sm"
        >
          <option value="">Select</option>
          <option value="Students">Students</option>
          <option value="Software Engineers">Software Engineers</option>
          <option value="Entrepreneurs">Entrepreneurs</option>
          <option value="Job Seekers">Job Seekers</option>
          <option value="Recruiters">Recruiters</option>
          <option value="HR Professionals">HR Professionals</option>
          <option value="Freelancers">Freelancers</option>
          <option value="Internship Seekers">Internship Seekers</option>
          <option value="Consultants">Consultants</option>
          <option value="Academic Advisors">Academic Advisors</option>
          <option value="Industry Experts">Industry Experts</option>
          <option value="Educators">Educators</option>
          <option value="Hiring Managers">Hiring Managers</option>
          <option value="Career Counselors">Career Counselors</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.292 7.53a1 1 0 011.414 0L10 10.83l3.294-3.3a1 1 0 011.412 1.42l-4 4a1 1 0 01-1.416 0l-4-4a1 1 0 010-1.42z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default TargetAudience;
