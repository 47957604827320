import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

interface ScheduleModalProps {
  closeModal: () => void;
  isOpen: boolean;
  publishPost: any;
}

const ScheduleModal: React.FC<ScheduleModalProps> = ({
  closeModal,
  isOpen,
  publishPost,
}) => {
  const [dateTime, setDateTime] = useState<dayjs.Dayjs | null>(
    dayjs().add(30, "minutes")
  );

  const handleDateTimeChange = (newValue: dayjs.Dayjs | null) => {
    setDateTime(newValue);
  };

  const handleScheduleClick = () => {
    if (dateTime) {
      let payload = {
        postId: "f32113b5-5ac4-47ea-a023-7cd6cbb1b0bc",
        scheduledAt: dateTime.format("YYYY-MM-DDTHH:mm:ss"),
        timezone: "Asia/Kolkata",
      };
      publishPost(payload);
      closeModal();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) =>
        reason === "backdropClick" ? null : closeModal()
      }
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          top: "-20%",
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Schedule post
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModal}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePicker
              label="Select Date & Time"
              value={dateTime}
              onChange={handleDateTimeChange}
              minDateTime={dayjs().add(30, "minutes")} // Disable backward hours and dates
            />
          </DemoContainer>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="outlined"
          sx={{ borderColor: "#6161F1", color: "#6161F1" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ background: "#6161F1", color: "white" }}
          onClick={handleScheduleClick}
        >
          Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleModal;
