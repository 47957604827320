import React, { useState } from "react";
import forgotPasswordImage from "../../assets/svg/forgot-password.svg";
import { forgotPasswordEmail } from "./AuthHelper";
import Loader from "components/icons/Loader";
import LeftToRightMotion from "components/Motions/LeftToRightMotion";
import NewToast from "components/ToastMessges/NewToast";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setError("Email is required.");
      setToast({ message: "Email is required.", type: "error" });
      return;
    } else if (!emailPattern.test(email)) {
      setError("Please enter a valid email address.");
      setToast({
        message: "Please enter a valid email address.",
        type: "error",
      });
      return;
    }

    setLoading(true);

    try {
      let res = await forgotPasswordEmail(email);
      if (res.success) {
        setToast({
          message: res?.message,
          type: "success",
        });
      } else {
        setToast({
          message: res?.message || "something went wrong",
          type: "error",
        });
      }
    } catch (error) {
      setToast({
        message: "Failed to send reset password email.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {toast && (
        <NewToast
          message={toast.message}
          type={toast.type}
          vertical={"top"}
          horizontal={"right"}
          onClose={() => setToast(null)}
        />
      )}
      <LeftToRightMotion>
        <div className="relative mx-auto flex h-[85vh] w-full flex-col items-center justify-center p-4 md:flex-row md:p-10">
          {/* Image Section */}
          <div className="flex w-full flex-col items-center justify-center p-4 md:w-1/2 lg:py-8">
            <img
              src={forgotPasswordImage}
              alt="Forgot Password"
              className="h-auto max-w-full"
            />
          </div>

          {/* Form Section */}
          <div className="flex w-full flex-col items-center justify-center p-4 md:w-1/2 lg:py-8">
            <form
              onSubmit={handleSubmit}
              className="w-full rounded-lg bg-white p-6 shadow-md md:max-w-md md:p-8 lg:p-10"
            >
              <h3 className="mb-6 text-center text-2xl font-semibold">
                Reset Your Password
              </h3>
              <label className="mb-4 block text-left">
                <span className="text-gray-700">Email Address</span>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your email"
                  required
                />
                {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
              </label>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded bg-[#5334FF] px-6 py-3 text-white hover:bg-blue-600 disabled:bg-gray-400"
                  disabled={loading}
                >
                  <svg
                    className="mr-2 hidden h-5 w-5 text-white md:block md:h-4 md:w-8"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                  </svg>
                  Send Reset Password Link
                  {loading && <Loader />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </LeftToRightMotion>
    </>
  );
};

export default ForgotPassword;
