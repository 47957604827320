import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ImageState {
  imageUrl: string | null;
  regenerateImage: boolean;
  isLoading: boolean;
  isRemoveImageCliked: boolean;
}

const initialState: ImageState = {
  imageUrl: null,
  regenerateImage: false,
  isLoading: false,
  isRemoveImageCliked: false,
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    saveImage: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    removeImage: (state) => {
      state.imageUrl = null;
      state.isRemoveImageCliked = true;
    },
    setIsRemoveImageCliked: (state, action: PayloadAction<boolean>) => {
      state.isRemoveImageCliked = action.payload;
    },
    setReGenerateImage: (state, action: PayloadAction<boolean>) => {
      state.regenerateImage = action.payload;
    },
    setImageLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  saveImage,
  removeImage,
  setReGenerateImage,
  setImageLoading,
  setIsRemoveImageCliked,
} = imageSlice.actions;

export default imageSlice.reducer;
