import React from "react";

interface InputFieldProps {
  variant?: "outlined" | "filled" | "standard"; // Adjust based on MUI variants if using MUI's TextField
  extra?: string;
  label: string;
  placeholder: string;
  id: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  disabled?: boolean;
  onKeyDown: any;
}

const InputField: React.FC<InputFieldProps> = ({
  variant = "outlined",
  extra,
  label,
  placeholder,
  id,
  type,
  value,
  onChange,
  error,
  disabled,
  onKeyDown,
}) => {
  return (
    <div className={`input-field ${extra}`}>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
      >
        {label}
      </label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        aria-invalid={!!error}
        aria-describedby={error ? `${id}-error` : undefined}
        onKeyDown={onKeyDown}
        className={`mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-brand-500 focus:outline-none focus:ring-brand-500 sm:text-sm ${
          error ? "border-red-500" : ""
        }`}
      />
      {error && (
        <p
          id={`${id}-error`}
          className="mt-2 text-sm text-red-600 dark:text-red-400"
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default InputField;
