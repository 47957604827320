import React, { useState, useCallback } from "react";
// import { useSelector, useDispatch } from "react-redux";
import Confetti from "react-confetti";
import BottomToTopMotion from "components/Motions/BottomToTopMotion";
import UserDropdown from "views/quickpost/UserDropdown";
import NewToast from "components/ToastMessges/NewToast";
import LanguageSelector from "views/quickpost/LanguageSelector";
import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import TopicInput from "views/quickpost/TopicInput";
import { FaMagic } from "react-icons/fa";
import PoolResultArea from "./PoolResultArea";
import ContextInputField from "./ContextInputField";
import { generatePoolContentAPI } from "./PollGeneratorHelper";
import PublishButton from "views/quickpost/PublishButton";
import DurationSelector from "./DurationSelector";
import { publishPollApi } from "./PollGeneratorHelper";
import { setPublishButtonLoading } from "Store/PublishButtonLoadingSlice";
import { useSelector, useDispatch } from "react-redux";

const PollGenerator: React.FC = () => {
  // const dispatch = useDispatch();
  // const [action, setAction] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [UserErrorMessage, setUserErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [contextErrorMessage, setContextErrorMessage] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [action, setAction] = useState("");
  const [formData, setFormData] = useState({
    context: "",
    topic: "",
    language: "English",
  });
  const [pollData, setPollData] = useState<any>(null);
  const [toast, setToast] = React.useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const [SelectedId, setSelectedId] = useState({
    type: "",
    id: 0,
    imageUrl: "",
    name: "",
  });
  const publishButtonLoading = useSelector(
    (state: any) => state.publishButtonLoading?.isLoading
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (window.screen.width <= 767) {
    isMobile = true;
  }

  console.log("pollData", pollData);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      let key;
      if (e.target.name === "type") {
        setErrorMessage("");
        key = "topic";
      } else {
        key = e.target.name;
      }

      setFormData({
        ...formData,
        [key]: e.target.value,
      });
    },
    [formData]
  );

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const generateContent = async () => {
    if (!formData.topic) {
      setContextErrorMessage(true);
      setToast({
        message: "Topic field is required",
        type: "error",
      });
      return;
    } else {
      if (contextErrorMessage) {
        setContextErrorMessage(false);
      }
    }
    setPollData(null);
    setContentLoading(true);
    try {
      const payload = {
        usecaseId: "poll-generation",
        context: formData.context,
        topic: formData.topic,
        language: formData.language,
      };
      const response = await generatePoolContentAPI(payload);
      if (response.success) {
        setPollData(response.data);
        let id = sessionStorage.getItem("PostSessionId");
        if (!id) {
          sessionStorage.setItem("PostSessionId", response?.data?.sessionId);
        }
        setOpenDrawer(true);
      }
    } catch (e) {
      console.log(e);
      setToast({
        message: "Error occurred while generating poll",
        type: "error",
      });
    } finally {
      setContentLoading(false);
    }
  };

  const publishPool = async (sheduleInfo: any) => {
    if (!SelectedId.id) {
      setUserErrorMessage(true);
      setOpenDrawer(!openDrawer);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setToast({
        message: "Please Choose an Account",
        type: "error",
      });
      return;
    }
    let content = pollData.post;
    let copyData = { ...pollData };
    delete copyData.post;
    const PostSessionId = sessionStorage.getItem("PostSessionId");
    let response;
    let payload = {
      sessionId: PostSessionId,
      content: content,
      ...(SelectedId?.type === "Users"
        ? { linkedinUserId: SelectedId.id }
        : { linkedinOrganizationId: SelectedId.id }),
      ...copyData,
    };
    if (sheduleInfo?.scheduledAt) {
      payload = {
        scheduledAt: sheduleInfo?.scheduledAt,
        timezone: sheduleInfo?.timezone,
        ...payload,
      };
    }
    dispatch(setPublishButtonLoading(true));
    response = await publishPollApi(payload);
    if (response.success) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 6000);
      if (response?.data?.linkedinPollUrl) {
        setAction(response.data.linkedinPollUrl);
      } else {
        setAction("");
      }
      setToast({
        message: response?.message || "Poll generated successfully!",
        type: "success",
      });
      dispatch(setPublishButtonLoading(false));
    } else {
      setAction("");
      dispatch(setPublishButtonLoading(false));
      setToast({
        message: response?.message || "Something Went Wrong",
        type: "error",
      });
    }
  };

  const setDuration = (duration: string) => {
    let data = { ...pollData, duration: duration };
    setPollData(data);
  };

  return (
    <>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          gravity={0.1}
          drawShape={(ctx) => {
            ctx.beginPath();
            for (let i = 0; i < 22; i++) {
              const angle = 0.35 * i;
              const x = (0.2 + 1.5 * angle) * Math.cos(angle);
              const y = (0.2 + 1.5 * angle) * Math.sin(angle);
              ctx.lineTo(x, y);
            }
            ctx.stroke();
            ctx.closePath();
          }}
          style={{ zIndex: 60 }}
        />
      )}
      <div className="flex w-[100%] flex-col md:flex-row">
        {toast && (
          <NewToast
            message={toast.message}
            type={toast.type}
            vertical={"top"}
            horizontal={"right"}
            onClose={() => setToast(null)}
            action={action}
          />
        )}
        {/* Left section */}
        <div className="flex h-[93.7vh] w-[100%] flex-col border-[1px] border-gray-200 bg-white bg-clip-border px-8 py-2 shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none md:w-[50%]">
          <div className="scrollbar w-full flex-1 overflow-y-auto scroll-smooth">
            <BottomToTopMotion>
              <div>
                <UserDropdown
                  setSelectedId={setSelectedId}
                  UserErrorMessage={UserErrorMessage}
                  setUserErrorMessage={setUserErrorMessage}
                />
              </div>
              <div className="mt-[0.5rem]">
                <TopicInput
                  content={formData}
                  handleInputChange={handleInputChange}
                  errorMessage={errorMessage}
                />
                {contextErrorMessage && (
                  <div className="mt-2 text-sm text-red-600">
                    This field is required
                  </div>
                )}
              </div>
              <div className="mt-[0.5rem]">
                <ContextInputField
                  content={formData}
                  handleInputChange={handleInputChange}
                  errorMessage={errorMessage}
                  label={"Context"}
                  name={"context"}
                />
              </div>
              <div className="mt-[0.5rem]">
                <LanguageSelector
                  language={formData.language}
                  handleInputChange={handleInputChange}
                />
              </div>
            </BottomToTopMotion>
          </div>
          <div className="mt-4 flex flex-col justify-between space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
            <button
              onClick={generateContent}
              disabled={contentLoading}
              className={`flex w-full items-center justify-center rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 py-2 text-center text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 ${
                contentLoading ? "cursor-not-allowed opacity-50" : ""
              } px-6 dark:focus:ring-blue-800`}
            >
              <FaMagic className="mr-2" />
              {contentLoading ? "Generating..." : "Generate"}
            </button>

            {pollData && (
              <button
                onClick={handleDrawer}
                className="flex w-full items-center justify-center rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 px-4 py-2 text-center text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 sm:w-auto lg:hidden"
              >
                <FaMagic className="mr-2" />
                View Output
              </button>
            )}
          </div>
        </div>

        {/* Right section */}
        {isMobile && pollData ? (
          <Drawer
            anchor="bottom"
            open={openDrawer}
            onClose={handleDrawer}
            PaperProps={{
              style: { maxHeight: "85%" },
            }}
            BackdropProps={{
              onClick: handleDrawer,
            }}
          >
            <div
              style={{
                height: "100vh",
                padding: "0.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  alignSelf: "flex-start",
                  width: "100%",
                }}
              >
                <PoolResultArea
                  pollData={pollData}
                  contentLoading={contentLoading}
                  SelectedId={SelectedId}
                  editPoll={setPollData}
                />
                {pollData && (
                  <div className="flex justify-center">
                    <span>
                      <label className="mb-1 block text-sm font-medium text-gray-700">
                        Poll Duration
                      </label>
                      <DurationSelector
                        defaultValue={pollData?.duration}
                        setDuration={setDuration}
                      />
                    </span>
                  </div>
                )}
              </div>

              <div
                style={{
                  alignSelf: "flex-end",
                  width: "100%",
                }}
              >
                <div>
                  <PublishButton
                    isWritingFinished={true}
                    isDrawer={true}
                    publishPost={publishPool}
                    finalSavebuttonLoading={publishButtonLoading}
                  />
                </div>
              </div>
            </div>
          </Drawer>
        ) : (
          <div className="hidden h-[93.7vh] w-[100%] flex-col  dark:!bg-navy-800 dark:text-white dark:shadow-none md:block md:w-[70%]">
            <div className="scrollbar h-[83vh] w-full flex-1 overflow-y-auto scroll-smooth bg-gray-100 p-4">
              <PoolResultArea
                pollData={pollData}
                contentLoading={contentLoading}
                SelectedId={SelectedId}
                editPoll={setPollData}
              />
            </div>
            <div className=" flex h-[10.7vh] items-end justify-between">
              <div className="mb-[1rem] ml-[3rem] flex-1">
                {pollData && (
                  <>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Poll Duration
                    </label>
                    <DurationSelector
                      defaultValue={pollData?.duration}
                      setDuration={setDuration}
                    />
                  </>
                )}
              </div>
              <div className="mb-[1rem] mr-[2rem] w-[20rem]">
                <PublishButton
                  isWritingFinished={true}
                  isDrawer={true}
                  publishPost={publishPool}
                  finalSavebuttonLoading={publishButtonLoading}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PollGenerator;
