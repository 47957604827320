import { useNavigate } from "react-router-dom";
import notImageAvaiable from "./allPostImages/imagesNotAvaiable.png";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const gotoPostGenerator = () => {
    navigate("/home/default");
  };

  return (
    <main className=" bg-blueSecondar grid h-screen place-items-center">
      <div className="mb-24 text-center">
        {/* <p className="text-base font-semibold text-indigo-600">404</p> */}
        <img src={notImageAvaiable} alt="Error for not post" className="h-60" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          No Post Available
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          No posts yet! Ready to create your first one?
        </p>
        <div className="mt-10 flex justify-center">
          <button
            onClick={gotoPostGenerator}
            className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
          >
            Create Post
          </button>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
