import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateContext } from "Store/contentSlice";
import { generateContentAPI } from "./QuickPostHelper";
import { FaMagic } from "react-icons/fa";
import ToneSelector from "./tone";
import LengthSelector from "./LengthSelector";
import TemplateSelector from "./TemplateSelector";
import KeywordChooser from "./KeywordChooser";
import ResultArea from "./ResultArea";
import { ContentState } from "Store/contentSlice";
import { publishPostAsync, SchedulePostAsync } from "./QuickPostHelper";
import Confetti from "react-confetti";
import BottomToTopMotion from "components/Motions/BottomToTopMotion";
import TopicInput from "./TopicInput";
import CallToActionInput from "./CallToActionInput";
import LanguageSelector from "./LanguageSelector";
import TargetAudience from "./TargetAudience";
import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import PublishButton from "./PublishButton";
import UserDropdown from "./UserDropdown";
import NewToast from "components/ToastMessges/NewToast";
import ImageReGenerateButton from "./ImageRenerateButton";
import { setIsRemoveImageCliked } from "Store/ImageSlice";
import { setPublishButtonLoading } from "Store/PublishButtonLoadingSlice";

const MemoizedToneSelector = React.memo(ToneSelector);
const MemoizedLengthSelector = React.memo(LengthSelector);
const MemoizedTemplateSelector = React.memo(TemplateSelector);
const MemoizedKeywordChooser = React.memo(KeywordChooser);

const PostContent: React.FC = () => {
  const content = useSelector((state: any) => state.content);
  const imageUrl = useSelector((state: any) => state.image.imageUrl);
  const publishButtonLoading = useSelector(
    (state: any) => state.publishButtonLoading?.isLoading
  );
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<ContentState>(content);
  const [contentLoading, setContentLoading] = useState(false);
  const [contentGeneratingLoading, setContentGeneratingLoading] =
    useState(false);
  const [generateContentData, setGenerateContentData] = useState("");
  const [FinalgenerateContentData, setFinalgenerateContentData] = useState("");
  const [isWritingFinished, setIsWritingFinished] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [UserErrorMessage, setUserErrorMessage] = useState(false);
  const [generateImage, setGenerateImage] = useState(false);
  const [CopygenerateImage, setCopyGenerateImage] = useState(false);
  const [SelectedId, setSelectedId] = useState({
    type: "",
    id: 0,
    imageUrl: "",
    name: "",
  });
  const [toast, setToast] = React.useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const [action, setAction] = useState("");

  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (window.screen.width <= 767) {
    isMobile = true;
  }

  useEffect(() => {
    dispatch(updateContext(formData));
  }, [formData, dispatch]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (e.target.name === "type") {
        setErrorMessage("");
      }

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const generateContent = async () => {
    localStorage.setItem("skipwriting", "false");
    dispatch(setIsRemoveImageCliked(false));
    if (!content?.type || content.type.trim() === "") {
      setErrorMessage("Please add some topic.");
      return;
    }

    if (content.type.length < 2) {
      setErrorMessage("Please add at least two characters.");
      return;
    }
    try {
      setIsWritingFinished(false);
      setContentLoading(true);
      setCopyGenerateImage(generateImage);
      let res = await generateContentAPI(content);
      if (res.success) {
        let id = sessionStorage.getItem("PostSessionId");
        if (!id) {
          sessionStorage.setItem("PostSessionId", res?.data?.sessionId);
        }
        setGenerateContentData(res.data?.output[0]?.text);
        setFinalgenerateContentData(res.data?.output[0]?.text);
        setOpenDrawer(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setContentLoading(false);
    }
  };

  const publishPost = async (sheduleInfo: any) => {
    if (!SelectedId.id) {
      setUserErrorMessage(true);
      setOpenDrawer(!openDrawer);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setToast({
        message: "Please Choose an Account",
        type: "error",
      });

      return;
    }
    console.log("SelectedId", SelectedId);
    console.log("payloadd", sheduleInfo);
    let res;
    const image = CopygenerateImage ? { imageUrl: imageUrl } : undefined;
    dispatch(setPublishButtonLoading(true));
    if (!sheduleInfo?.scheduledAt) {
      res = await publishPostAsync(
        FinalgenerateContentData,
        SelectedId.id,
        image,
        SelectedId?.type === "Users"
      );
    } else {
      let payload = {
        scheduledAt: sheduleInfo?.scheduledAt,
        timezone: sheduleInfo?.timezone,
        content: FinalgenerateContentData,
        ...(SelectedId?.type === "Users"
          ? { linkedinUserId: SelectedId.id }
          : { linkedinOrganizationId: SelectedId.id }),
        ...(imageUrl && CopygenerateImage ? { imageUrl: imageUrl } : ""),
      };
      res = await SchedulePostAsync(payload);
    }
    if (res.success) {
      setOpenDrawer(!openDrawer);
      setShowConfetti(true);
      sessionStorage.removeItem("PostSessionId");
      setGenerateImage(false);
      setTimeout(() => setShowConfetti(false), 5000);
      if (res?.data?.linkedinPostUrl) {
        setAction(res.data.linkedinPostUrl);
      } else {
        setAction("");
      }
      setToast({
        message: res?.message || "Posted Successfully",
        type: "success",
      });
      dispatch(setPublishButtonLoading(false));
    } else {
      setAction("");
      dispatch(setPublishButtonLoading(false));
      setToast({
        message: res?.message || "Something Went Wrong",
        type: "error",
      });
    }
  };

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
    localStorage.setItem("skipwriting", "true");
  };

  const GenerateImage = () => {
    setGenerateImage(!generateImage);
  };

  return (
    <>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          gravity={0.1}
          drawShape={(ctx) => {
            ctx.beginPath();
            for (let i = 0; i < 22; i++) {
              const angle = 0.35 * i;
              const x = (0.2 + 1.5 * angle) * Math.cos(angle);
              const y = (0.2 + 1.5 * angle) * Math.sin(angle);
              ctx.lineTo(x, y);
            }
            ctx.stroke();
            ctx.closePath();
          }}
          style={{ zIndex: 60 }}
        />
      )}
      <div className="flex w-[100%] flex-col md:flex-row">
        {toast && (
          <NewToast
            message={toast.message}
            type={toast.type}
            vertical={"top"}
            horizontal={"right"}
            onClose={() => setToast(null)}
            action={action}
          />
        )}
        {/* Left section */}
        <div className="flex h-[93.7vh] w-[100%] flex-col border-[1px] border-gray-200 bg-white bg-clip-border px-8 py-2 shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none md:w-[50%]">
          <div className="scrollbar w-full flex-1 overflow-y-auto scroll-smooth">
            <BottomToTopMotion>
              <div className="mr-[10px] space-y-1">
                <UserDropdown
                  setSelectedId={setSelectedId}
                  UserErrorMessage={UserErrorMessage}
                  setUserErrorMessage={setUserErrorMessage}
                />

                <TopicInput
                  content={content}
                  handleInputChange={handleInputChange}
                  errorMessage={errorMessage}
                />

                <MemoizedTemplateSelector
                  formData={content}
                  setFormData={setFormData}
                />
                <MemoizedKeywordChooser
                  formData={content}
                  setFormData={setFormData}
                />
                <TargetAudience // Use TargetAudience Component
                  targetAudience={content.targetAudience}
                  handleInputChange={handleInputChange}
                />

                <CallToActionInput
                  content={content}
                  handleInputChange={handleInputChange}
                />

                <MemoizedToneSelector
                  formData={content}
                  setFormData={setFormData}
                />
                <div className="mb-4 gap-4 md:flex">
                  <div className="mt-[0.3rem]">
                    <LanguageSelector
                      language={content.language}
                      handleInputChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-[0.3rem] flex-1">
                    <MemoizedLengthSelector
                      length={content.length}
                      setFormData={setFormData}
                    />
                  </div>
                </div>
                <div className="me-4 flex items-center">
                  <label
                    htmlFor="yellow-checkbox"
                    className="flex items-center"
                  >
                    <input
                      checked={generateImage}
                      id="yellow-checkbox"
                      type="checkbox"
                      onChange={GenerateImage}
                      className="h-4 w-4 rounded border-gray-300 bg-gray-100 dark:border-gray-600 dark:bg-gray-700"
                    />
                    <span className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Generate AI Image
                    </span>
                  </label>
                </div>
              </div>
            </BottomToTopMotion>
          </div>
          <div className="mt-4 flex flex-col justify-between space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
            <button
              onClick={generateContent}
              disabled={contentLoading || contentGeneratingLoading}
              className={`flex w-full items-center justify-center rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 py-2 text-center text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 ${
                contentLoading || contentGeneratingLoading
                  ? "cursor-not-allowed opacity-50"
                  : ""
              } px-6 dark:focus:ring-blue-800`}
            >
              <FaMagic className="mr-2" />
              {contentLoading || contentGeneratingLoading
                ? "Generating..."
                : "Generate"}
            </button>

            {generateContentData && (
              <button
                onClick={handleDrawer}
                className="flex w-full items-center justify-center rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 px-4 py-2 text-center text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 sm:w-auto lg:hidden"
              >
                <FaMagic className="mr-2" />
                View Output
              </button>
            )}
          </div>
        </div>

        {/* Right section */}
        {isMobile && generateContentData ? (
          <Drawer
            anchor="bottom"
            open={openDrawer}
            onClose={handleDrawer}
            PaperProps={{
              style: { maxHeight: "75%", height: "75%" },
            }}
            BackdropProps={{
              onClick: handleDrawer,
            }}
          >
            <div
              style={{
                height: "100vh",
                padding: "0.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  alignSelf: "flex-start",
                  width: "100%",
                }}
              >
                <ResultArea
                  contentLoading={contentLoading}
                  generateContentData={generateContentData}
                  isWritingFinished={isWritingFinished}
                  setIsWritingFinished={setIsWritingFinished}
                  setContentGeneratingLoading={setContentGeneratingLoading}
                  setGenerateContentData={setFinalgenerateContentData}
                  SelectedId={SelectedId}
                  CopygenerateImage={CopygenerateImage}
                />
              </div>
              <div
                style={{
                  alignSelf: "flex-end",
                  width: "100%",
                }}
              >
                <div>
                  <PublishButton
                    isWritingFinished={isWritingFinished}
                    isDrawer={true}
                    publishPost={publishPost}
                    finalSavebuttonLoading={publishButtonLoading}
                  />
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ImageReGenerateButton
                    generateImage={CopygenerateImage}
                    isDrawer={true}
                  />
                </div>
              </div>
            </div>
          </Drawer>
        ) : (
          <div className="hidden h-[93.7vh] w-[100%] flex-col  dark:!bg-navy-800 dark:text-white dark:shadow-none md:block md:w-[70%]">
            <div className=" scrollbar h-[83vh] w-full flex-1 overflow-y-auto scroll-smooth bg-gray-100 p-4">
              <ResultArea
                contentLoading={contentLoading}
                generateContentData={generateContentData}
                isWritingFinished={isWritingFinished}
                setIsWritingFinished={setIsWritingFinished}
                setContentGeneratingLoading={setContentGeneratingLoading}
                setGenerateContentData={setFinalgenerateContentData}
                SelectedId={SelectedId}
                CopygenerateImage={CopygenerateImage}
              />
            </div>
            <div className="mr-[5rem] flex h-[10.7vh] flex-col justify-end">
              <div className="mb-[1rem] flex flex-row justify-end space-x-4">
                <ImageReGenerateButton
                  generateImage={CopygenerateImage}
                  isDrawer={false}
                />
                <PublishButton
                  isWritingFinished={isWritingFinished}
                  isDrawer={false}
                  publishPost={publishPost}
                  finalSavebuttonLoading={publishButtonLoading}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PostContent;
