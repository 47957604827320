import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getAccessToken, setAccessToken } from "views/auth/AuthHelper";
import { useSelector } from "react-redux";

interface ProtectedRouteProps {
  redirectPath?: string;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirectPath = "/auth/",
  children,
}) => {
  const location = useLocation();
  let isAuthenticated = getAccessToken();
  let isLinkedInSkip = localStorage.getItem("linkedInSkip");
  const userAuthentication = useSelector(
    (state: any) => state.userAuthentication
  );
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get("token");

  if (tokenFromUrl) {
    setAccessToken(tokenFromUrl);
    isAuthenticated = tokenFromUrl;
  }

  if (isAuthenticated) {
    if (!userAuthentication?.isLinkedinConnected && !isLinkedInSkip) {
      navigate("/auth/linkedin-signup");
    }
    return <>{children}</>;
  }
  return <Navigate to={redirectPath} replace state={{ from: location }} />;
};

export default ProtectedRoute;
