import axios from "axios";
import { getAccessToken } from "views/auth/AuthHelper";

export const getAllScheduledPosts = async (page = 1, limit = 10) => {
  let token = getAccessToken();
  try {
    const url = `${process.env.REACT_APP_API_URL}/posts?status=scheduled&page=${page}&limit=${limit}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getAllpublishedPosts = async (page = 1, limit = 10) => {
  let token = getAccessToken();
  try {
    const url = `${process.env.REACT_APP_API_URL}/posts?status=published&page=${page}&limit=${limit}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getAllScheduledpoll = async (page = 1, limit = 10) => {
  let token = getAccessToken();
  try {
    const url = `${process.env.REACT_APP_API_URL}/poll?status=scheduled&page=${page}&limit=${limit}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getAllpublishedpoll = async (page = 1, limit = 10) => {
  let token = getAccessToken();
  try {
    const url = `${process.env.REACT_APP_API_URL}/poll?status=published&page=${page}&limit=${limit}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

const handleAxiosError = (error) => {
  if (axios.isAxiosError(error)) {
    console.error(
      "Error with GET request:",
      error.response?.data || error.message
    );
    return {
      message: error.response?.data?.message || "An error occurred",
      statusCode: error.response?.status || 500,
    };
  } else {
    console.error("Unexpected error:", error);
    return {
      message: "An unexpected error occurred",
      statusCode: 500,
    };
  }
};

export function getLinkedinEntity(linkedinUsers, id, entityType) {
  // Check if linkedinUsers array is valid
  if (!Array.isArray(linkedinUsers) || linkedinUsers.length === 0) {
    return null;
  }

  // Checking for entityType user
  if (entityType === "user") {
    for (let user of linkedinUsers) {
      if (user && user.linkedinUserId === id) {
        return user.profileUrl || null; // Ensure return is either profileUrl or null
      }
    }
  }
  // Checking for entityType organization
  else if (entityType === "organization") {
    for (let user of linkedinUsers) {
      if (user && Array.isArray(user.linkedinOrganizations)) {
        for (let organization of user.linkedinOrganizations) {
          if (organization && organization.linkedinOrganizationId === id) {
            return organization.organizationLogo || null; // Ensure return is either organizationLogo or null
          }
        }
      }
    }
  }

  // If ID not found or entityType invalid
  return null;
}
