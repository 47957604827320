import { useState, useEffect } from "react";
import PublishPost from "./PublishPost";
import ScheduledPost from "./ScheduledPost";
import PublishedPoll from "./PublishedPoll";
import ScheduledPoll from "./ScheduledPoll";
import {
  getAllScheduledPosts,
  getAllpublishedPosts,
  getAllScheduledpoll,
  getAllpublishedpoll,
} from "./allPostHelper";
import { AiOutlineSchedule } from "react-icons/ai";
import { MdOutlinePublishedWithChanges } from "react-icons/md";

const AllPosts = () => {
  const [activeParentTab, setActiveParentTab] = useState("posts"); // 'posts' or 'polls'
  const [displayScheduled, setDisplayScheduled] = useState(false); // Toggle between scheduled and published
  const [posts, setPosts] = useState({
    scheduled: [],
    published: [],
    totalScheduled: 0,
    totalPublished: 0,
  });
  const [polls, setPolls] = useState({
    scheduled: [],
    published: [],
    totalScheduled: 0,
    totalPublished: 0,
  });
  const [loading, setLoading] = useState(false);

  const fetchPostsAndPolls = async () => {
    setLoading(true);
    try {
      const [
        scheduledPostsRes,
        publishedPostsRes,
        scheduledPollsRes,
        publishedPollsRes,
      ] = await Promise.all([
        getAllScheduledPosts(1, 10),
        getAllpublishedPosts(1, 10),
        getAllScheduledpoll(1, 10),
        getAllpublishedpoll(1, 10),
      ]);

      // Set Posts
      setPosts({
        scheduled: scheduledPostsRes?.data?.posts ?? [],
        published: publishedPostsRes?.data?.posts ?? [],
        totalScheduled: scheduledPostsRes?.data?.totalPosts || 0,
        totalPublished: publishedPostsRes?.data?.totalPosts || 0,
      });

      // Set Polls
      setPolls({
        scheduled: scheduledPollsRes?.data?.polls ?? [],
        published: publishedPollsRes?.data?.polls ?? [],
        totalScheduled: scheduledPollsRes?.data?.totalPolls || 0,
        totalPublished: publishedPollsRes?.data?.totalPolls || 0,
      });
    } catch (error) {
      console.error("Error fetching posts and polls:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPostsAndPolls();
  }, []);

  if (loading) return null;

  return (
    <div className="rounded-lg bg-white p-6 shadow-md">
      {/* Parent Tabs for Posts and Polls */}
      <div className="mb-4">
        <ul className="flex border-b">
          <li
            onClick={() => setActiveParentTab("posts")}
            className={`mr-6 cursor-pointer pb-2 ${
              activeParentTab === "posts"
                ? "border-b-2 border-blue-600 font-semibold text-blue-600"
                : "text-gray-500 hover:text-blue-600"
            }`}
          >
            Posts
          </li>
          <li
            onClick={() => setActiveParentTab("polls")}
            className={`mr-6 cursor-pointer pb-2 ${
              activeParentTab === "polls"
                ? "border-b-2 border-blue-600 font-semibold text-blue-600"
                : "text-gray-500 hover:text-blue-600"
            }`}
          >
            Polls
          </li>
        </ul>
      </div>

      {/* Child Tabs for Scheduled and Published */}
      <div className="border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
        <ul className="-mb-px flex flex-wrap">
          <li
            className="me-2"
            onClick={() => setDisplayScheduled(!displayScheduled)}
          >
            <span
              style={{ cursor: "pointer" }}
              className={`flex rounded-t-lg border-b-2 p-4 ${
                !displayScheduled
                  ? "active border-blue-600 text-blue-600 dark:border-blue-500 dark:text-blue-500"
                  : "border-transparent hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300"
              }`}
            >
              <MdOutlinePublishedWithChanges className="mr-2 flex" size={20} />{" "}
              Published{" "}
              {activeParentTab === "posts"
                ? posts.totalPublished
                : polls.totalPublished}
            </span>
          </li>
          <li
            className="me-2"
            onClick={() => setDisplayScheduled(!displayScheduled)}
          >
            <span
              style={{ cursor: "pointer" }}
              className={`flex rounded-t-lg border-b-2 p-4 ${
                displayScheduled
                  ? "active border-blue-600 text-blue-600 dark:border-blue-500 dark:text-blue-500"
                  : "border-transparent hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300"
              }`}
            >
              <AiOutlineSchedule className="mr-2" size={20} /> Scheduled{" "}
              {activeParentTab === "posts"
                ? posts.totalScheduled
                : polls.totalScheduled}
            </span>
          </li>
        </ul>
      </div>

      {/* Render content based on the active tabs */}
      {!displayScheduled ? (
        activeParentTab === "posts" ? (
          <PublishPost publishedPosts={posts.published} />
        ) : (
          <PublishedPoll publishedPolls={polls.published} />
        )
      ) : activeParentTab === "posts" ? (
        <ScheduledPost scheduledPosts={posts.scheduled} />
      ) : (
        <ScheduledPoll scheduledPolls={polls.scheduled} />
      )}
    </div>
  );
};

export default AllPosts;
