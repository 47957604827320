import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import routes from "routes";
import AuthNavbar from "components/navbar/AuthNavbar";
import { getAccessToken, getUserInfo } from "views/auth/AuthHelper";
import { UserData } from "Store/loginController";
import FullScreenLoader from "components/FullScreenLoader";
import WithoutLoginInterComm from "components/intercomm/WithoutLoginInterComm";

const getRoutes = (routes: any) => {
  return routes.map((prop: any, key: any) => {
    if (prop.layout === "/auth") {
      return (
        <Route path={`/${prop.path}`} element={prop.component} key={key} />
      );
    }
    return null;
  });
};

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = getAccessToken();
  let isLinkedInSkip = localStorage.getItem("linkedInSkip");
  let pathName = window?.location?.pathname;

  useEffect(() => {
    const fetchDetails = async () => {
      if (isAuthenticated) {
        setLoading(true);
        try {
          const res = await getUserInfo(isAuthenticated);
          if (res.success) {
            dispatch(UserData(res.data));

            if (
              pathName.includes("/auth/reset-password") ||
              pathName.includes("/auth/verify-email")
            ) {
              const params = new URLSearchParams(window.location.search);
              const tokenFromUrl = params.get("token");
              localStorage.setItem("verifytoken", tokenFromUrl);
              if (tokenFromUrl) {
                navigate(pathName);
                return;
              }
            }
            if (res?.data?.isLinkedinConnected || isLinkedInSkip) {
              navigate("/home/default");
            } else {
              navigate("/auth/linkedin-signup");
            }
          }
        } catch (error) {
          console.error("Failed to fetch user details:", error);
          navigate("/error");
        } finally {
          setLoading(false);
        }
      } else {
        if (
          pathName.includes("/auth/reset-password") ||
          pathName.includes("/auth/verify-email")
        ) {
          const params = new URLSearchParams(window.location.search);
          const tokenFromUrl = params.get("token");
          localStorage.setItem("verifytoken", tokenFromUrl);
          if (tokenFromUrl) {
            navigate(pathName);
            return;
          } else {
            navigate("/error");
          }
        }
      }
    };

    if (pathName !== "/auth/linkedin") {
      fetchDetails();
    }
  }, [isAuthenticated, isLinkedInSkip, dispatch, navigate, pathName]);
  //, dispatch, navigate

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <div className="scrollbar relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
      <main className="mx-auto min-h-screen">
        <AuthNavbar />
        <div className="relative flex">
          <div className="mx-auto flex min-h-full w-full flex-col justify-start md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[90vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
            <div className="flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/auth/sign-in" replace />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </main>
      <WithoutLoginInterComm />
    </div>
  );
}
