import { SlCalender } from "react-icons/sl";
import { TbBrandMailgun } from "react-icons/tb";
import { BiPoll } from "react-icons/bi";
import { CgCarousel } from "react-icons/cg";
import { BsPostcardHeart } from "react-icons/bs";
import { AiOutlineLinkedin } from "react-icons/ai";
import PostContent from "views/quickpost/PostContent";
import AllPosts from "views/AllPost/AllPosts";
import PollGenerator from "views/PollGenerator/PollGenerator";

const SideBarRoutes = [
  {
    name: "Post Generator",
    layout: "/home",
    path: "default",
    icon: <AiOutlineLinkedin className="h-6 w-6" />,
    component: <PostContent />,
    badge: "",
  },
  {
    name: "Poll Generator",
    layout: "/home",
    path: "poll-generator",
    icon: <BiPoll className="h-6 w-6" />,
    component: <PollGenerator />,
    // badge: "soon",
  },
  {
    name: "All Posts",
    layout: "/home",
    path: "all-posts",
    icon: <BsPostcardHeart className="h-6 w-6" />,
    component: <AllPosts />,
    badge: "",
  },
  {
    name: "Carousel Generator",
    layout: "/home",
    path: "Carousel-Generator",
    icon: <CgCarousel className="h-6 w-6" />,
    component: <PostContent />,
    badge: "soon",
  },
  {
    name: "Content Calendar",
    layout: "/home",
    path: "Content-Calendar",
    icon: <SlCalender className="h-6 w-6" />,
    component: <PostContent />,
    badge: "soon",
  },

  {
    name: "Brand Mentions",
    layout: "/home",
    path: "Brand-Mentions",
    icon: <TbBrandMailgun className="h-6 w-6" />,
    component: <PostContent />,
    badge: "soon",
  },
];

export default SideBarRoutes;
