import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { LuExternalLink } from "react-icons/lu";

const PollDialogBox = ({ poll, onClose }) => {
  return (
    <Dialog open={!!poll} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {/* Flexbox layout for title and LinkedIn link */}
        <div className="flex items-center justify-between">
          <span>Poll Details</span>

          {/* LinkedIn link */}
          {poll?.linkedinPollUrl && (
            <a
              href={poll?.linkedinPollUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-lg text-blue-500 hover:underline"
            >
              {/* Text and Icon */}
              Open in LinkedIn
              <LuExternalLink className="ml-1" />
            </a>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        {/* Poll Content Section */}
        <p className="mb-4 text-lg text-gray-800">{poll?.content}</p>

        {/* Poll Question Section */}
        <p className="mb-4 text-lg font-bold text-gray-800">{poll?.question}</p>

        {/* Poll Options */}
        <ul className="mt-3">
          {poll?.options.map((option, index) => (
            <li key={index} className="mb-3">
              <button
                className="flex w-full items-center justify-center rounded-md border border-blue-500 px-6 py-2 text-blue-500 
                           transition duration-150 ease-in-out hover:bg-blue-50 
                           focus:outline-none"
                disabled
              >
                {option}
              </button>
            </li>
          ))}
        </ul>

        {/* Poll Stats (votes and time left) */}
        <p className="mb-2 mt-4 text-sm text-gray-500">
          20 votes &middot; 2 days left
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PollDialogBox;
