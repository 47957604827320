import React, { useState, useEffect } from "react";
import { getTemplates } from "./QuickPostHelper";
import { Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface TemplateData {
  [key: string]: string;
}

interface TemplateSelectorProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  formData,
  setFormData,
}) => {
  const [templates, setTemplates] = useState<TemplateData>({});
  const [selectedTemplate, setSelectedTemplate] = useState<string>(
    formData.context || ""
  );
  const [open, setOpen] = useState<boolean>(false);

  // Fetch templates from the API
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const data = await getTemplates();
        if (data && typeof data === "object") {
          if (Object.values(data).every((value) => typeof value === "string")) {
            setTemplates(data);
          } else {
            console.error("Unexpected data format:", data);
          }
        } else {
          console.error("Invalid data received:", data);
        }
      } catch (error) {
        console.error("Failed to fetch templates:", error);
      }
    };

    fetchTemplates();
  }, []);

  const handleTemplateClick = (context: string) => {
    setSelectedTemplate(context);
    setFormData((prevData: any) => ({ ...prevData, context }));
    setOpen(false);
  };

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    setSelectedTemplate(newValue);
    setFormData((prevData: any) => ({ ...prevData, context: newValue }));
  };

  return (
    <div>
      <div className="relative">
        <span
          onClick={() => setOpen(true)}
          className="mr-2 mt-2 rounded-lg p-2 text-indigo-500 hover:text-indigo-700"
          style={{
            position: "absolute",
            bottom: "15px",
            right: "9px",
            color: "#6161F1",
            textDecorationColor: "#6161F1",
            fontSize: "small",
            cursor: "pointer",
            background: "white",
          }}
        >
          Choose Template
        </span>
        <label className="mb-1 block text-sm font-medium text-gray-700">
          Context
        </label>
        <textarea
          className="scrollbar min-h-[4.65rem] w-full rounded-lg border border-gray-300 p-2 text-sm text-gray-900"
          value={selectedTemplate}
          placeholder="Enter context or Select a template"
          rows={3}
          onChange={handleTextareaChange}
        ></textarea>
      </div>

      {/* Drawer for template selection */}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: "w-full sm:w-1/2 lg:w-1/3" }}
      >
        <div className="p-4" style={{ boxSizing: "border-box" }}>
          <h1 className="text-lg text-gray-700">Select a Template</h1>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="mt-8 ">
            <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
              {Object.entries(templates).map(([key, template]) => (
                <div
                  key={key}
                  className="flex h-full flex-col justify-between overflow-hidden rounded-lg border-[1px] border-gray-200 bg-white shadow-lg"
                >
                  <div className="flex-grow p-4">
                    <p className="whitespace-pre-line text-sm text-gray-700">
                      {template.replace(/\\n/g, "\n")}
                    </p>
                  </div>
                  <div className="flex justify-center p-4">
                    <button
                      className="rounded-lg bg-indigo-500 px-4 py-2 text-white  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                      onClick={() => handleTemplateClick(template)}
                    >
                      Select
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default TemplateSelector;
