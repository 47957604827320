import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/svg/Logo.svg";

const AuthNavbar: React.FC = () => {
  const navigate = useNavigate();
  const SignInPageRedirect = () => {
    navigate("/auth/sign-in");
  };

  return (
    <div className="m-0 h-[8vh] bg-gray-100 font-sans text-navy-700 dark:bg-navy-700 dark:text-white">
      <div className=" bg-white  dark:bg-navy-700">
        <div className="mx-auto px-4">
          <div className="flex items-center justify-between py-1 ">
            <div onClick={SignInPageRedirect} className="cursor-pointer">
              <img
                src={Logo}
                alt="Forgot Password"
                className="p-[0.8rem] h-[4rem]"
              />
            </div>

            {/* <div
              className="dark:text-white sm:flex sm:items-center"
              style={{ fontFamily: "sans-serif" }}
            >
              <span
                className="cursor-pointer text-lg font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                onClick={SignInPageRedirect}
              >
                Sign In
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthNavbar;
