// // src/components/QuickpostButtons.tsx
// import React from "react";
// import "./QuickpostButtons.css";

// const QuickpostButtons: React.FC = () => {
//   return (
//     /* From Uiverse.io by vinodjangid07 */
//     <button className="Btn-Container ml=[2px]">
//       <span className="textt">Quick Post</span>
//       <span className="icon-Container">
//         <svg
//           width="16"
//           height="19"
//           viewBox="0 0 16 19"
//           fill="nones"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <circle cx="1.61321" cy="1.61321" r="1.5" fill="black"></circle>
//           <circle cx="5.73583" cy="1.61321" r="1.5" fill="black"></circle>
//           <circle cx="5.73583" cy="5.5566" r="1.5" fill="black"></circle>
//           <circle cx="9.85851" cy="5.5566" r="1.5" fill="black"></circle>
//           <circle cx="9.85851" cy="9.5" r="1.5" fill="black"></circle>
//           <circle cx="13.9811" cy="9.5" r="1.5" fill="black"></circle>
//           <circle cx="5.73583" cy="13.4434" r="1.5" fill="black"></circle>
//           <circle cx="9.85851" cy="13.4434" r="1.5" fill="black"></circle>
//           <circle cx="1.61321" cy="17.3868" r="1.5" fill="black"></circle>
//           <circle cx="5.73583" cy="17.3868" r="1.5" fill="black"></circle>
//         </svg>
//       </span>
//     </button>
//   );
// };

// export default QuickpostButtons;
// import React from "react";
import { FaEdit } from "react-icons/fa";

function QuickpostButtons() {
  return (
    // <button className="mb-[1rem] flex w-[80%] items-center justify-center rounded-lg bg-indigo-500 px-6 py-2 text-center text-white">
    <button className="mb-2 me-2 mt-[-0.5rem] flex w-[85%] items-center justify-center rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800">
      <FaEdit className="mb-[2px] mr-2 h-4 w-4" />
      Quick Post
    </button>
  );
}

export default QuickpostButtons;
