import React, { useState, useEffect, useCallback } from "react";
import { generateImageAPI } from "./QuickPostHelper";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { saveImage, removeImage, setImageLoading } from "Store/ImageSlice";
import NewToast from "components/ToastMessges/NewToast";

interface ImageComponentProps {
  parentGenerateImage: boolean;
  generateContentData: any;
  skipLoading: any;
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  parentGenerateImage,
  generateContentData,
  skipLoading,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(parentGenerateImage);
  const { imageUrl, regenerateImage, isLoading } = useSelector(
    (state: any) => state.image
  );
  const [toast, setToast] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  console.log("regenerateImage", regenerateImage);
  useEffect(() => {
    if (parentGenerateImage && !skipLoading) {
      dispatch(setImageLoading(true));
      generateImageAsync();
    }
  }, [parentGenerateImage, generateContentData, regenerateImage]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateImageAsync = useCallback(async () => {
    dispatch(setImageLoading(true));
    setVisible(true);
    const PostSessionId = sessionStorage.getItem("PostSessionId");
    const payload = {
      usecaseId: "post-image",
      sessionId: PostSessionId,
      imageSummary: generateContentData,
    };
    try {
      const res = await generateImageAPI(payload);
      if (res.success) {
        dispatch(saveImage(res.data?.imageUrl));
        setVisible(true);
      } else {
        setToast({
          message: res.message || "Failed to load image.",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error generating image", error);
    } finally {
      dispatch(setImageLoading(false));
    }
  }, [generateContentData, regenerateImage, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRemoveImage = () => {
    dispatch(removeImage());
    setVisible(false);
  };

  if (visible === false) {
    return null;
  }
  if (isLoading === false && !imageUrl) {
    return null;
  }

  return (
    <>
      {toast && (
        <NewToast
          message={toast.message}
          type={toast.type}
          vertical={"top"}
          horizontal={"right"}
          onClose={() => setToast(null)}
        />
      )}{" "}
      <div className="relative mb-[1rem] h-64 w-auto">
        {isLoading ? (
          <div className="relative flex h-full w-full animate-pulse items-center justify-center rounded-lg bg-gray-200">
            <div className="h-16 w-16 rounded-full bg-gray-300" />
          </div>
        ) : (
          <>
            <img
              src={imageUrl ?? ""}
              alt="Loaded content"
              className="h-full w-full rounded-lg object-cover"
            />
            <button
              onClick={handleRemoveImage}
              className="absolute right-2 top-2 flex items-center justify-center rounded-full bg-red-500 p-1 text-white focus:outline-none"
            >
              <FaTimes size={16} />
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ImageComponent;
