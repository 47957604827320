import axios from "axios";
import { getAccessToken } from "views/auth/AuthHelper";

export const getUsecase = async () => {
  let token = getAccessToken();
  try {
    const url = `${process.env.REACT_APP_API_URL}/generate/usecase`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const getTemplates = async () => {
  let token = getAccessToken();
  try {
    const url = `${process.env.REACT_APP_API_URL}/generate/templates`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const generateMockData = () => {
  const randomNumber = Math.floor(Math.random() * 100) + 1;
  return {
    message: "Request processed successfully.",
    data: {
      sessionId: "fa165caa-1d03-4ff1-b736-28006929d71f",
      output: [
        {
          text:
            "🌟 Full Stack Development has evolved! 🌟\n\n𝗕𝗲𝗳𝗼𝗿𝗲: Limited tech stacks.\n𝗕𝗲𝗳𝗼𝗿𝗲: Manual deployments.\n𝗕𝗲𝗳𝗼𝗿𝗲: Isolated databases.\n\nExactly! It's a 𝘯𝘦𝘸 𝘦𝘳𝘢 🕰️\n\nAI-driven development 🤖\nSeamless React & Node integration ⚙️\nScalable Azure SQL solutions 💾\n\nStop complaining and start adapting! This is the 2024 way to embrace full stack development.\n\n#FullStackDev #AI #React #NodeJS #SQL #Azure #TechTrends2024 🚀  Full Stack Development has evolved! 🌟\n\n𝗕𝗲𝗳𝗼𝗿𝗲: Limited tech stacks.\n𝗕𝗲𝗳𝗼𝗿𝗲: Manual deployments.\n𝗕𝗲𝗳𝗼𝗿𝗲: Isolated databases.\n\nExactly! It's a 𝘯𝘦𝘸 𝘦𝘳𝘢 🕰️\n\nAI-driven development 🤖\nSeamless React & Node integration ⚙️\nScalable Azure SQL solutions 💾\n\nStop complaining and start adapting! This is the 2024 way to embrace full stack development.\n\n#FullStackDev #AI #React #NodeJS #SQL #Azure #TechTrends2024 🚀" +
            randomNumber,
          words: 60,
        },
      ],
    },
    success: true,
  };
};

export const generateContentAPI = async (content: object) => {
  let token = getAccessToken();

  // Check if in development mode and return mock data
  if (process.env.NODE_ENV === "development") {
    return new Promise((resolve) => {
      setTimeout(() => resolve(generateMockData()), 500); // Simulate API delay
    });
  }

  try {
    const url = `${process.env.REACT_APP_API_URL}/generate`;
    const response = await axios.post(url, content, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const generateMockImage = () => {
  return {
    message: "Request processed successfully.",
    data: {
      sessionId: "de427fa3-ef2a-4a88-bb2e-83cd87c954d3",
      imageUrl:
        "https://postsensy-generate-image-stg.s3.ap-south-1.amazonaws.com/1724579734020_8a91aff4-c3bf-43ee-a06a-6460233ff97c.png",
    },
    success: true,
  };
};

export const generateImageAPI = async (payload: object) => {
  let token = getAccessToken();
  if (process.env.NODE_ENV === "development") {
    return new Promise((resolve) => {
      setTimeout(() => resolve(generateMockImage()), 3000);
    });
  }
  try {
    const url = `${process.env.REACT_APP_API_URL}/generate/images`;
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const publishPostAsync = async (
  data: any,
  selectedAccount: any,
  imageUrl: any,
  isUser: any
) => {
  let token = getAccessToken();
  let payload = {
    content: data,
    ...(isUser
      ? { linkedinUserId: selectedAccount }
      : { linkedinOrganizationId: selectedAccount }),
    ...(imageUrl ? imageUrl : ""),
  };
  try {
    const url = `${process.env.REACT_APP_API_URL}/posts/publish`;
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const SchedulePostAsync = async (payload: any) => {
  let token = getAccessToken();
  try {
    const url = `${process.env.REACT_APP_API_URL}/posts/publish`;
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const getConnectUrl = () => {
  let frontendUrl = window.location.origin;
  let clientId = process.env.REACT_APP_LIKEDIN_CLIENT_ID;
  let scops = [
    "openid",
    "profile",
    "r_ads_reporting",
    "r_organization_social",
    "rw_organization_admin",
    "w_member_social",
    "r_ads",
    "w_organization_social",
    "rw_ads",
    "r_basicprofile",
    "r_organization_admin",
    "email",
    "r_1st_connections_size",
  ];
  const redirectUrl = frontendUrl + "/auth/linkedin";
  const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scops.join(
    " "
  )}&state=123456789&nonce=987654321`;
  return authUrl;
};
