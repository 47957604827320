import { useNavigate } from "react-router-dom";
import LinkedInButton from "components/buttons/LinkedInButton";
import { useSelector } from "react-redux";

const LinkedInSignIn = () => {
  const navigate = useNavigate();
  const userAuthentication = useSelector(
    (state: any) => state.userAuthentication
  );
  const LinkedInLoginSkip = () => {
    localStorage.setItem("linkedInSkip", "true");
    navigate("/auth");
  };
  return (
    <div className="relative flex h-[92vh] flex-col items-center justify-center p-4 text-navy-700 dark:text-white">
      <h1 className="mb-4 text-center text-4xl font-bold">
        Welcome to PostSensy
      </h1>
      <p className="mb-8 text-center text-gray-700">
        Get ready to expand your LinkedIn network with PostSensy — where
        creators and influencers thrive!
      </p>
      <LinkedInButton
        skip={false}
        status={userAuthentication?.isLinkedinConnected}
      />
      <button
        onClick={LinkedInLoginSkip}
        className="w-[100px] hover:bg-gray-100"
      >
        Skip
      </button>
    </div>
  );
};

export default LinkedInSignIn;
