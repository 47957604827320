import NFTMarketplace from "views/home/marketplace";
import DataTables from "views/home/tables";
import ForgotPassword from "views/auth/ForgotPassword";
import EmailVerification from "views/auth/EmailVerification";
import PostContent from "views/quickpost/PostContent";
import VerifyToken from "views/auth/VerifyToken";
import ResetPassword from "views/auth/ResetPassword";
import LinkedInSignIn from "views/auth/LinkedInSignIn";
import LinkedInVerify from "views/auth/LinkedInVerify";
import AllPosts from "views/AllPost/AllPosts";
import PollGenerator from "views/PollGenerator/PollGenerator";
import { BsPostcardHeart } from "react-icons/bs";
import UserProfile from "views/UserProfile/UserProfile/UserProfile";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  // MdPerson,
  MdLock,
} from "react-icons/md";

const routes = [
  {
    name: "Post Generator",
    layout: "/home",
    path: "default",
    icon: <MdHome className="h-6 w-6 " />,
    component: <PostContent />,
  },
  {
    name: "NFT Marketplace",
    layout: "/home",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/home",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Post Generator",
    layout: "/home",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "quick-post",
    component: <PostContent />,
  },
  {
    name: "linkedin-signup",
    layout: "/auth",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "linkedin-signup",
    component: <LinkedInSignIn />,
  },

  // {
  //   name: "Profile",
  //   layout: "/home",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Forgot password page",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
  },
  {
    name: "reset password page",
    layout: "/auth",
    path: "reset-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ResetPassword />,
  },
  {
    name: "email verifivation page",
    layout: "/auth",
    path: "email-verification",
    icon: <MdLock className="h-6 w-6" />,
    component: <EmailVerification />,
  },
  {
    name: "verify Token",
    layout: "/auth",
    path: "verify-email",
    icon: <MdLock className="h-6 w-6" />,
    component: <VerifyToken />,
  },
  {
    name: "LinkedIn Verify",
    layout: "/auth",
    path: "/linkedin/*",
    icon: <MdLock className="h-6 w-6" />,
    component: <LinkedInVerify />,
  },
  {
    name: "All Posts",
    layout: "/home",
    path: "all-posts",
    icon: <MdHome className="h-6 w-6 " />,
    component: <AllPosts />,
  },
  {
    name: "Poll Generator",
    layout: "/home",
    path: "poll-generator",
    icon: <BsPostcardHeart className="h-6 w-6" />,
    component: <PollGenerator />,
    badge: "",
  },
  {
    name: "User Profile",
    layout: "/home",
    path: "user-profile",
    icon: <BsPostcardHeart className="h-6 w-6" />,
    component: <UserProfile />,
    badge: "",
  },
];
export default routes;
