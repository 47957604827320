import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa"; // Import add icon

interface KeywordChooserProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

const KeywordChooser: React.FC<KeywordChooserProps> = ({
  formData,
  setFormData,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Ensure that keywords key exists in formData
  useEffect(() => {
    if (!formData.keywords) {
      setFormData((prevData: any) => ({ ...prevData, keywords: [] }));
    }
  }, [formData, setFormData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setErrorMessage("");
  };

  const handleAddKeyword = () => {
    if (!inputValue) {
      setErrorMessage("Please enter a keyword");
      return;
    }
    if (inputValue.trim() !== "" && !formData?.keywords?.includes(inputValue)) {
      if (formData?.keywords?.length === 5) {
        setErrorMessage("You can only add up to 5 keywords.");
        return;
      }
      const newKeywords = [...formData?.keywords, inputValue];
      setFormData({ ...formData, keywords: newKeywords });
      setInputValue(""); // Clear input after adding
      setErrorMessage(null); // Clear error message
    } else {
      setErrorMessage("Invalid keyword or keyword already exists.");
    }
  };

  const handleRemoveKeyword = (keyword: string) => {
    const newKeywords = formData?.keywords.filter((k: string) => k !== keyword);
    setFormData({ ...formData, keywords: newKeywords });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddKeyword();
    }
  };

  return (
    <div className="space-y-2">
      <label className="mb-1 block text-sm font-medium text-gray-700">
        Keywords
      </label>
      <div className="flex space-x-2">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          className="w-full rounded-lg border border-gray-300 p-2 text-sm text-gray-900"
          placeholder="Type a keyword and Enter"
        />
        <FaPlus
          onClick={handleAddKeyword}
          className="cursor-pointer self-center text-[#6161F1]"
          size={15} // Adjust the size of the icon
        />
      </div>
      {errorMessage && (
        <div className="mt-2 text-sm text-red-500">{errorMessage}</div>
      )}
      <div className="mt-2 flex flex-wrap gap-2">
        {formData?.keywords?.map((keyword: string) => (
          <div
            key={keyword}
            className="flex items-center space-x-2 rounded-full bg-gray-200 px-3 py-1 text-sm text-gray-700"
          >
            <span>{keyword}</span>
            <button
              onClick={() => handleRemoveKeyword(keyword)}
              className="text-red-500 hover:text-red-700"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeywordChooser;
