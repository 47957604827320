import { useRef, useEffect } from "react";
import Links from "./components/Links";
import { useNavigate } from "react-router-dom";
import QuickpostButtons from "components/buttons/QuickpostButtons";
import LinkedInButton from "components/buttons/LinkedInButton";
import { useSelector } from "react-redux";
import Logo from "../../assets/svg/Logo.svg";
import SideBarRoutes from "./SideBarRoutes";
import { PiUserCircle } from "react-icons/pi";

const Sidebar = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const userAuthentication = useSelector(
    (state: any) => state.userAuthentication
  );
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleQuickPostClick = () => {
    if (window.screen.width < 1200) {
      onClose();
    }
    navigate("/home/quick-post");
  };

  const handleClickOutside = (event: MouseEvent) => {
    const targetNode = event.target as Node;
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(targetNode) &&
      window.screen.width < 1200 &&
      !(targetNode instanceof Element && targetNode.closest(".navbar-custom"))
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps
  const goToProfile = () => {
    if (window.screen.width < 1200) {
      onClose();
    }
    navigate("/home/user-profile");
  };
  return (
    <div
      style={{ borderRight: "1px solid rgb(203 213 224)" }}
      ref={sidebarRef}
      className={`fixed z-50 flex min-h-full flex-col transition-transform duration-500 ${
        open ? "translate-x-0" : "-translate-x-full"
      } bg-white pb-6 dark:bg-navy-800 dark:text-white`}
    >
      <div className="mx-11 flex items-center">
        <div className="ml-[-30px] h-2 font-poppins font-bold text-navy-700 dark:text-white">
          <img
            src={Logo}
            alt="Logo"
            style={{ marginTop: "6px", marginLeft: "1.5rem", height: "2.7rem" }}
          />
        </div>
      </div>
      <div
        className="mb-7 mt-10 bg-gray-300 dark:bg-white/30"
        style={{ height: "0.5px" }}
      />
      <div onClick={handleQuickPostClick}>
        <div className="flex justify-center">
          <QuickpostButtons />
        </div>
      </div>
      <ul className="mb-auto pt-1">
        <Links routes={SideBarRoutes} toggleSideBar={onClose} />
      </ul>
      {/* Free PostSensy Card */}
      <div className="flex justify-center">
        {/* <SidebarCard /> */}
        {!userAuthentication?.isLinkedinConnected && (
          <LinkedInButton
            skip={true}
            status={userAuthentication?.isLinkedinConnected}
          />
        )}
      </div>
      <p className="text-md mb-2 ml-2 text-center font-semibold text-gray-700">
        <div
          onClick={goToProfile}
          className={`relative flex items-center px-[15px] py-[10px] transition-all duration-300 ease-in-out hover:ml-1 hover:bg-gray-200`}
        >
          <span className="text-black font-medium">
            <PiUserCircle size={20} />
          </span>
          <p className={`leading-1 ml-2 flex font-medium text-gray-700`}>
            {"User Profile"}
          </p>
        </div>
      </p>
      <p className="text-md mb-0 text-center font-semibold text-gray-700">
        Made with <span className="text-violet-600">💜</span> by PostSensy
      </p>
    </div>
  );
};

export default Sidebar;
