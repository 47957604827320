import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "components/icons/Loader";
import { verifyEmail, getUserInfo, setAccessToken } from "./AuthHelper";
import { useDispatch } from "react-redux";
import { UserData } from "Store/loginController";

const VerifyToken: React.FC = () => {
  const navigate = useNavigate(); // To navigate programmatically
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  // Extract the token from the URL
  const currentUrl = window.location.href;
  const urlObj = new URL(currentUrl);
  let token = urlObj.searchParams.get("token");
  if (!token) {
    let verifytoken = localStorage.getItem("verifytoken");
    token = verifytoken;
  }

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setError("No token provided");
        setLoading(false);
        return;
      }
      try {
        const response = await verifyEmail(token);
        if (response?.data?.success) {
          let res = await getUserInfo(response?.data?.data?.accessToken);
          setAccessToken(response?.data?.data?.accessToken);
          dispatch(UserData(res?.data));
          navigate("/home/default");
        } else {
          navigate("/error");
        }
      } catch (err) {
        setError((err as Error)?.message || "An unexpected error occurred");
      } finally {
        setLoading(false);
        localStorage.removeItem("verifytoken");
      }
    };

    verifyToken();
  }, [token, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return null;
};

export default VerifyToken;
