import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/home";
import AuthLayout from "layouts/auth";
import NotFoundPage from "views/ErrorPages/NotFoundPage";
import ProtectedRoute from "layouts/protectedRoute/PrivateRoute";

const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="home/*"
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      />
      <Route path="error" element={<NotFoundPage />} />
      <Route path="/" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default App;
