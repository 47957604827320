import axios from "axios";

export const UserSignUp = async (formValues: {
  name: string;
  email: string;
  password: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/sign-up`;
    const response = await axios.post(url, formValues);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const UserSignIn = async (formValues: {
  email: string;
  password: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/login`;
    const response = await axios.post(url, formValues);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const forgotPasswordEmail = async (email: string) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/reset-password`;
    const response = await axios.post(url, { email });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const verifyEmail = async (token: string) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/auth/verify-email?token=${token}`;
    const response = await axios.post(url);
    return response;
  } catch (error) {
    console.error("Error with POST request:", error);
  }
};

export const verifyLikedIn = async (
  token: any,
  state: any,
  isAuthenticated: any
) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/auth/linkedin/callback?state=${state}&code=${token}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `${isAuthenticated}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error with POST request:", error);
  }
};

export const getUserInfo = async (token: string) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/users`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with GET request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const googleSignUp = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/google/oauth`;
    let res = await axios.get(url);
    return res?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with GET request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};
export const LinkedInSignUp = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/linkedin`;
    let res = await axios.get(url);
    return res?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with GET request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};
export const LogOutLinkedIn = async (isAuthenticated: any, id: String) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/users/linkedin/disconnect`;
    const response = await axios.patch(
      url,
      {
        linkedinUserId: id,
      },
      {
        headers: {
          Authorization: `${isAuthenticated}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with GET request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

interface PasswordResetResponse {
  message: string;
  statusCode: number;
  [key: string]: any;
}

export const UserPasswordReset = async (payload: {
  token: string | null;
  newPassword: string;
}): Promise<PasswordResetResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/update-password`;
    const res = await axios.patch(url, payload);
    return res?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "Error with POST request:",
        error.response?.data || error.message
      );
      return {
        message: error.response?.data?.message || "An error occurred",
        statusCode: error.response?.status || 500,
      };
    } else {
      console.error("Unexpected error:", error);
      return {
        message: "An unexpected error occurred",
        statusCode: 500,
      };
    }
  }
};

export const UserLogOut = async () => {
  let token = getAccessToken();
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/logout`;
    await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Unexpected error:", error);
  }
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem("accessToken", token);
};

export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const removeAccessToken = () => {
  localStorage.removeItem("accessToken");
};
