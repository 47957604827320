import React from "react";

type Tone =
  | "Humorous"
  | "Formal"
  | "Negative"
  | "Serious"
  | "Curious"
  | "Friendly";

const toneIcons: Record<Tone, any> = {
  Humorous: "😂",
  Formal: "🧑‍💼",
  Negative: "😠",
  Serious: "🧐",
  Curious: "🤔",
  Friendly: "😊",
};

const ToneSelector: React.FC<any> = ({ setFormData, formData }) => {
  const tones: Tone[] = [
    "Humorous",
    "Formal",
    "Negative",
    "Serious",
    "Curious",
    "Friendly",
  ];

  return (
    <div className="mb-4" style={{ marginTop: "10px" }}>
      <label className="mb-1 block text-sm font-medium text-gray-700">
        Select the Tone
      </label>
      <div className="flex flex-wrap gap-2">
        {tones.map((tone) => {
          const isSelected = formData.tone === tone;
          const baseStyles =
            "flex items-center gap-2 rounded-full px-2 py-1 text-sm font-medium transition-all duration-100 focus:outline-none focus:ring-2";
          const selectedStyles =
            "bg-[#6161F1] text-white focus:ring-indigo-500";
          const unselectedStyles =
            "bg-gray-200 text-gray-700 hover:bg-navy-50 hover:text-gray-700 focus:ring-indigo-500";

          return (
            <div
              key={tone}
              className={`${baseStyles} ${
                isSelected ? selectedStyles : unselectedStyles
              }`}
              onClick={() =>
                setFormData({
                  ...formData,
                  tone: formData.tone === tone ? "" : tone,
                })
              }
              style={{ padding: "0.25rem 1.2rem 0.25rem 0.75rem" }}
            >
              {toneIcons[tone]}
              <span>{tone}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ToneSelector;
