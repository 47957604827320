import React, { useState, useEffect, useRef } from "react";
import avatar from "assets/img/avatars/user.png";
import { getConnectUrl } from "./QuickPostHelper";
import { useSelector } from "react-redux";

interface Organization {
  linkedinOrganizationId: number;
  organizationName: string;
  localizedName?: string | null;
  localizedWebsite?: string | null;
  organizationLogo?: string | null;
}

interface User {
  linkedinUserId: number;
  name: string;
  email: string;
  profileUrl?: string | null;
  linkedinOrganizations: Organization[];
}

interface DropdownOption {
  label: string;
  value: number;
  imageUrl?: string | null; // Added this to store the profile image URL
}

interface UserDropdownProps {
  setSelectedId: any;
  UserErrorMessage: any;
  setUserErrorMessage: any;
}

const UserDropdown: React.FC<UserDropdownProps> = ({
  UserErrorMessage,
  setSelectedId,
  setUserErrorMessage,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DropdownOption | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const state = useSelector((state: any) => state);
  let users = state?.userAuthentication?.linkedinUsers;

  const toggleDropdown = () => {
    if (users?.length === 0) {
      LinkedInLogin();
    } else {
      setIsOpen(!isOpen);
    }
  };

  const LinkedInLogin = () => {
    let res = getConnectUrl();
    window.open(res, "_self");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleSelect = (item: DropdownOption, section: any) => {
    setSelectedItem(item);
    let data = {
      type: section?.label,
      id: item?.value,
      imageUrl: item?.imageUrl,
      name: item?.label,
    };
    setSelectedId(data);
    setUserErrorMessage(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const userOptions: DropdownOption[] = users?.map((user: User) => ({
    label: user.name,
    value: user.linkedinUserId,
    imageUrl: user.profileUrl,
  }));

  const organizationOptions: DropdownOption[] = users?.flatMap((user: User) =>
    user.linkedinOrganizations.map((org: Organization) => ({
      label: org.organizationName,
      value: org.linkedinOrganizationId,
      imageUrl: org.organizationLogo,
    }))
  );

  const dropdownOptions = [
    {
      label: "Users",
      options: userOptions,
    },
    {
      label: "Pages",
      options: organizationOptions,
    },
  ];

  return (
    <div ref={dropdownRef} style={{ marginBottom: "5px" }}>
      <label className="mb-1 block text-sm font-medium text-gray-700">
        Account
      </label>
      <button
        id="dropdownUsersButton"
        style={{ color: "black" }}
        onClick={toggleDropdown}
        className={`inline-flex items-center rounded-lg bg-[#F3F3F3] px-5 py-2 text-center text-sm font-medium text-white hover:bg-navy-50 focus:outline-none  focus:ring-blue-300`}
        type="button"
      >
        {selectedItem ? (
          <span className="flex items-center">
            <img
              className="mr-2 h-6 w-6 rounded-full"
              src={selectedItem.imageUrl || avatar}
              alt={selectedItem.label}
            />
            <span className="max-w-[100px] truncate">{selectedItem.label}</span>
          </span>
        ) : users?.length === 0 ? (
          "Connect Linkedin"
        ) : (
          "Select an Author"
        )}
        {users?.length !== 0 && (
          <svg
            className="ml-3 h-2.5 w-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1l4 4 4-4"
            />
          </svg>
        )}
      </button>

      {isOpen && (
        <div
          id="dropdownUsers"
          className="absolute z-10 mt-2 w-60 rounded-lg bg-white shadow dark:bg-gray-700"
        >
          {dropdownOptions?.map((section, index) => (
            <div key={index}>
              {section?.options?.length > 0 && (
                <>
                  <div className="px-2 py-0 text-sm font-thin italic  text-gray-700 dark:text-gray-200">
                    {section.label}
                  </div>
                  <ul className="h-auto overflow-y-auto py-2">
                    {section?.options?.map((option) => (
                      <li
                        key={option?.value}
                        onClick={() => handleSelect(option, section)}
                      >
                        <span className="flex cursor-pointer items-center px-4 py-1 hover:bg-navy-50 dark:hover:bg-gray-600 dark:hover:text-white">
                          <img
                            className="mr-2 h-6 w-6 rounded-full"
                            src={option.imageUrl || avatar}
                            alt={option.label}
                          />
                          {option.label}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          ))}
        </div>
      )}

      {UserErrorMessage && (
        <div className="mt-2 text-sm text-red-600">
          {users?.length === 0
            ? "Please connect Linkedin first"
            : "Please select an account"}
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
