import { FaLinkedin } from "react-icons/fa";
import { getConnectUrl } from "views/quickpost/QuickPostHelper";

function LinkedInButton(props) {
  const LinkedInLogin = () => {
    let res = getConnectUrl();
    window.open(res, "_self");
  };

  return (
    <button
      onClick={LinkedInLogin}
      className="text-md mb-[0.5rem]  flex w-[14rem]  items-center justify-center rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 px-5 py-2.5  text-sm font-medium text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
      //className="flex items-center rounded-lg bg-indigo-500 px-6 py-2 text-white w-[85%]"
    >
      <FaLinkedin className="mr-2 h-4 w-4" />
      Connect LinkedIn
    </button>
  );
}

export default LinkedInButton;
