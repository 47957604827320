import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FaPrayingHands } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import avatar from "assets/img/avatars/user.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeAccessToken } from "views/auth/AuthHelper";
import { useDispatch } from "react-redux";
import NewToast from "components/ToastMessges/NewToast";
import { LOGOUT } from "../../Store/actionTypes";
import { UserLogOut } from "views/auth/AuthHelper";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  className?: string;
}) => {
  const { onOpenSidenav, brandText, className } = props;
  const dispatch = useDispatch();
  const [toast, setToast] = React.useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const navigate = useNavigate();
  const userAuthentication = useSelector(
    (state: any) => state.userAuthentication
  );
  let linedinData = userAuthentication?.linkedinUsers?.[0];
  const profilePic = linedinData?.profileUrl;
  const url = window.location.href;
  const lastSegment = url.split("/").pop();

  const LogOut = async () => {
    await UserLogOut();
    removeAccessToken();
    localStorage.clear();
    dispatch({ type: LOGOUT });
    navigate("/auth/sign-in");
  };

  return (
    <>
      {toast && (
        <NewToast
          message={toast.message}
          type={toast.type}
          vertical={"top"}
          horizontal={"right"}
          onClose={() => setToast(null)}
        />
      )}
      <nav
        className={`bg-t z-40 flex h-[48.5px] w-full flex-row flex-wrap items-center justify-between dark:bg-[#0b14374d] ${className}`}
        style={{
          borderLeft: "0.5px solid rgb(203 213 224)",
          position: "sticky",
          top: "0",
          borderBottom: "0.5px solid rgb(203 213 224)",
          background: "rgb(255 255 255)",
        }}
      >
        <div className="ml-[6px]">
          <div className="h-6 w-[224px] pl-3">
            {/* <a
              className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              href=" "
            >
              Pages
              <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                {" "}
                /{" "}
              </span>
            </a> */}
            <Link
              className="text-md font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              to="#"
            >
              {lastSegment === "quick-post" ? "Post Generator" : brandText}
            </Link>
          </div>
        </div>

        <div
          className="relative flex h-[3rem] w-[355px] flex-grow items-center justify-around gap-2 px-2 py-2 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"
          style={{
            width: "auto",
            position: "absolute",
            right: "0",
            background: "rgb(255 255 255)",
            borderBottom: "0.5px solid rgb(203 213 224)",
          }}
        >
          {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
        />
      </div> */}
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={onOpenSidenav}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>
          <Dropdown
            button={
              <p className="cursor-pointer">
                <IoMdNotificationsOutline className="h-6 w-6 text-gray-600 dark:text-white" />
              </p>
            }
            animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
            children={
              <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    Notification
                  </p>
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    Mark all read
                  </p>
                </div>
                <button className="flex w-full items-center">
                  <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                    <FaPrayingHands />
                  </div>
                  <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                    <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                      Welcome to PostSensy
                    </p>
                    <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                      Keep Posting!
                    </p>
                  </div>
                </button>
              </div>
            }
            classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
          />
          {/* start PostSensy PRO */}

          {/* <div
        className="cursor-pointer text-gray-600"
        onClick={() => {
          if (darkmode) {
            document.body.classList.remove("dark");
            setDarkmode(false);
          } else {
            document.body.classList.add("dark");
            setDarkmode(true);
          }
        }}
      >
        {darkmode ? (
          <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
        ) : (
          <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
        )}
      </div> */}
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <img
                className="h-8 w-8 rounded-full"
                src={profilePic || avatar}
                alt="Profile Avatar"
              />
            }
            children={
              <div className="flex h-[100%] w-56 cursor-pointer flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat p-0 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="ml-4 mt-2">
                  <div className="flex items-center gap-2">
                    <p className="rounded-lg p-2 text-sm font-bold text-navy-700 hover:bg-gray-100 dark:text-white">
                      {`👋 Hey, ${userAuthentication?.name || "User"}`}
                    </p>
                  </div>
                </div>
                <div className="mt-2 h-px w-full bg-gray-200 dark:bg-white/20 " />
                <div className="my-2 ml-4 flex flex-col ">
                  <span
                    onClick={LogOut}
                    className="rounded-lg p-2 text-sm font-medium text-red-500 hover:bg-gray-100 hover:text-red-500"
                  >
                    Log Out
                  </span>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
