import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ImageState {
  isLoading: boolean;
}

const initialState: ImageState = {
  isLoading: false,
};

const PublishButtonLoadingSlice = createSlice({
  name: "publishButtonLoading",
  initialState,
  reducers: {
    setPublishButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setPublishButtonLoading } = PublishButtonLoadingSlice.actions;

export default PublishButtonLoadingSlice.reducer;
