import React, { useState, useEffect, useRef } from "react";
import postReaction from "../../assets/svg/post-reactions.svg";
import avatar from "assets/img/avatars/user.png";
import Skeleton from "@mui/material/Skeleton";
import BottomToTopMotion from "components/Motions/BottomToTopMotion";
import { FiEdit } from "react-icons/fi";

interface PollData {
  post: string;
  question: string;
  options: string[];
}

interface SelectedId {
  imageUrl?: string;
  name?: string;
}

interface PollResultAreaProps {
  pollData: PollData | null;
  contentLoading: boolean;
  SelectedId: SelectedId | null;
  editPoll: (data: PollData) => void;
}

const PollResultArea: React.FC<PollResultAreaProps> = ({
  pollData: initialPollData,
  contentLoading,
  SelectedId,
  editPoll,
}) => {
  const headline = "Software Engineer";
  const likes = 450;
  const comments = 108;
  const shares = 4;
  const [randomQuote, setRandomQuote] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [pollData, setPollData] = useState<PollData | null>(initialPollData);
  const editablePostRef = useRef<HTMLDivElement>(null);
  const editableQuestionRef = useRef<HTMLDivElement>(null);
  const editableOptionsRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);
  }, []);

  useEffect(() => {
    setPollData(initialPollData);
    if (initialPollData?.options) {
      editableOptionsRefs.current = initialPollData.options.map(() => null);
    }
  }, [initialPollData]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = () => {
    if (!pollData) return;

    const updatedPost = editablePostRef.current?.textContent || "";
    const updatedQuestion = editableQuestionRef.current?.textContent || "";
    const updatedOptions = editableOptionsRefs.current
      .map((ref) => ref?.textContent || "")
      .filter((option) => option.trim() !== "");

    const updatedPollData: PollData = {
      ...pollData,
      post: updatedPost,
      question: updatedQuestion,
      options: updatedOptions,
    };
    editPoll(updatedPollData);
    setPollData(updatedPollData);
    setIsEditing(false);
  };

  const handleAddOption = () => {
    if (pollData && pollData.options.length < 4) {
      const updatedPollData: PollData = {
        ...pollData,
        options: [...pollData.options, ""],
      };
      setPollData(updatedPollData);
      editableOptionsRefs.current = [...editableOptionsRefs.current, null];
    }
  };
  const checkOptions = (options: any[]) =>
    options.filter((item) => item.length > 0).length > 2;

  const handleRemoveOption = (index: number) => {
    const updatedOptions = editableOptionsRefs.current
      .map((ref) => ref?.textContent || "")
      .filter((option) => option.trim() !== "");
    if (
      updatedOptions &&
      updatedOptions.length > 2 &&
      checkOptions(updatedOptions)
    ) {
      const updatedPollData: PollData = {
        ...pollData,
        options: updatedOptions.filter((_, i) => i !== index),
      };
      setPollData(updatedPollData);
      editableOptionsRefs.current = editableOptionsRefs.current.filter(
        (_, i) => i !== index
      );
    }
  };

  const renderEditControls = () => (
    <ul className="space-y-3">
      {pollData?.options.map((option, index) => (
        <li key={index} className="relative">
          <div
            contentEditable={isEditing}
            ref={(el) => (editableOptionsRefs.current[index] = el)}
            className={`w-full rounded-lg p-3 text-center text-blue-500 ${
              isEditing ? "border border-gray-600" : "border border-blue-500"
            }`}
          >
            {option}
          </div>
          {isEditing && pollData.options.length > 2 && (
            <button
              onClick={() => handleRemoveOption(index)}
              className="absolute right-3 top-3 text-red-500"
            >
              Remove
            </button>
          )}
        </li>
      ))}
      {pollData && pollData?.options?.length < 4 && isEditing && (
        <button onClick={handleAddOption} className="text-blue-500">
          Add Option
        </button>
      )}
    </ul>
  );

  const renderPostContent = () => {
    if (contentLoading) {
      return (
        <div className="mb-4">
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="90%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
        </div>
      );
    } else if (!pollData) {
      const splitQuote = randomQuote.split(" – ");
      return (
        <div className="mt-[16rem] text-center text-gray-500">
          <span className="block text-lg">{splitQuote[0]}</span>
          <span className="mt-2 block text-sm font-bold">
            – {splitQuote[1]}
          </span>
        </div>
      );
    } else {
      return (
        <>
          <div
            contentEditable={isEditing}
            suppressContentEditableWarning
            ref={editablePostRef}
            className={`text-gray-800 ${
              isEditing ? "rounded-lg border border-gray-600 p-2" : ""
            }`}
          >
            {pollData.post}
          </div>
          <div className="mt-2 rounded-lg border border-gray-300 bg-white p-4 shadow-sm">
            <div className="linkedin-poll-result space-y-4">
              <div
                contentEditable={isEditing}
                suppressContentEditableWarning
                ref={editableQuestionRef}
                className={`font-semibold text-gray-800 ${
                  isEditing ? "rounded-lg border border-gray-600 p-2" : ""
                }`}
              >
                {pollData.question}
              </div>
              {renderEditControls()}
              <div className="border-t pt-4 text-sm text-gray-500">
                20 votes • 2 days left
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  if (!pollData && !contentLoading) {
    return (
      <div className="text-center text-gray-500">
        <div className="mt-[16rem]">{renderPostContent()}</div>
      </div>
    );
  }

  return (
    <BottomToTopMotion>
      <div
        className="scrollbar mx-auto my-4 max-w-lg rounded-lg border border-gray-300 bg-white p-4 shadow-lg sm:max-h-[60vh] sm:min-w-[20rem] md:max-h-[75vh] md:min-w-[25rem]"
        style={{
          maxHeight: "75vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="mb-2 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={SelectedId?.imageUrl || avatar}
              alt="Profile"
              className="mr-4 h-12 w-12 rounded-full"
            />
            <div>
              <h3 className="text-lg font-semibold">
                {SelectedId?.name || "User"}
              </h3>
              <p className="text-sm text-gray-600">{headline}</p>
              <p className="text-xs text-gray-400">Just now</p>
            </div>
          </div>
          {!contentLoading && (
            <button onClick={isEditing ? handleSaveClick : handleEditClick}>
              {isEditing ? (
                <span className="rounded-lg bg-indigo-600 px-5 py-2.5 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-4 focus:ring-indigo-300">
                  Save
                </span>
              ) : (
                <span className="inline-flex items-center justify-center rounded-lg p-3 text-base font-medium hover:bg-gray-200 hover:text-gray-900">
                  <FiEdit size={18} className="mr-2" />
                  <span>Edit</span>
                </span>
              )}
            </button>
          )}
        </div>
        {renderPostContent()}
        <div className="mt-4 flex justify-between text-sm text-gray-600">
          <div className="flex items-center">
            <img src={postReaction} alt="Reactions" className="mr-2 h-4 w-10" />
            <span>{likes} likes</span>
          </div>
          <div className="flex space-x-4">
            <span>{comments} comments</span>
            <span>{shares} shares</span>
          </div>
        </div>
      </div>
    </BottomToTopMotion>
  );
};

export default PollResultArea;

const quotes = [
  "Content builds relationships. Relationships are built on trust. Trust drives revenue. – Andrew Davis",
  "People do not buy goods and services. They buy relations, stories, and magic. – Seth Godin",
  "The best marketing doesn't feel like marketing. – Tom Fishburne",
  "Content is the atomic particle of all digital marketing. – Rebecca Lieb",
  "Your brand is a story unfolding across all customer touch points. – Jonah Sachs",
  "Marketing is no longer about the stuff that you make, but about the stories you tell. – Seth Godin",
  "The future of marketing is about being helpful. – Jay Baer",
  "Content is anything that adds value to the reader's life. – Avinash Kaushik",
  "Good content isn't about good storytelling. It's about telling a true story well. – Ann Handley",
  "You can never go wrong by investing in communities and the human beings within them. – Pam Moore",
  "Content is King, but engagement is Queen, and the lady rules the house! – Mari Smith",
  "Content: there is no easy button. – Scott Abel",
  "Creating content that serves the needs of your audience is the only way to build a brand that lasts. – John Hall",
  "Quality content means being authentic, not perfect. – Ann Handley",
  "Your content leads to conversation. – Barry Feldman",
];
