import React, { useEffect } from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Link from "@mui/material/Link";

// CustomAlert component
const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(
  function CustomAlert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

interface ToastProps {
  message: string;
  type: "success" | "info" | "warning" | "error";
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
  onClose: (event?: React.SyntheticEvent, reason?: SnackbarCloseReason) => void;
  action?: any;
}

const NewToast: React.FC<ToastProps> = ({
  message,
  type,
  vertical,
  horizontal,
  onClose,
  action,
}) => {
  const timeout = action ? 10000 : 3000;
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        onClose();
      }, timeout);

      return () => clearTimeout(timer);
    }
  }, [message, onClose]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = (
    event?: React.SyntheticEvent,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(event, reason);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={!!message}
      autoHideDuration={timeout}
      key={vertical + horizontal}
    >
      <CustomAlert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {message}
        {action && (
          <>
            <br></br>
            <Link
              href={action}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              View Post
            </Link>
          </>
        )}
      </CustomAlert>
    </Snackbar>
  );
};

export default NewToast;
